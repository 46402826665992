import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import DocumentTitle from 'react-document-title';
import {
	Alert,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row,
	Progress
	// UncontrolledTooltip
} from 'reactstrap';

import { Line, Chart } from 'react-chartjs-2';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga4';
import { ma } from 'moving-averages';
import Select from 'react-select';

import {
	fetchLastUploadDate,
	resetLastUploadDate,
	fetchSites,
	resetSites,
	fetchStockpilesData,
	resetStockpilesData,
	fetchStockpilesDataInitial,
	fetchLocations,
	resetLocations,
	fetchGroups,
	resetGroups
} from 'actions';
import {
	colourPalette,
	fChartOptions,
	genChartStyle,
	annotationsStem,
	annotationStem,
	plugins
} from './chartConfig';
import { Crumbs } from 'containers';
import { DateRangePopover } from 'components';
import { multiSelectStyle } from './multiSelectStyle';
// import { smeltersInlineHelpContent } from './smeltersInlineHelpContent';

const config = {
	dateRangeDayLimit: 10,
	smoothingOptions: [
		{
			title: '2 day moving average',
			days: 2
		},
		{
			title: '4 day moving average',
			days: 4
		},
		{
			title: '7 day moving average',
			days: 7
		},
		{
			title: '14 day moving average',
			days: 14
		},
		{
			title: '21 day moving average',
			days: 21
		},
		{
			title: '30 day moving average',
			days: 30
		}
	],
	apiDateFormat: 'YYYYMMDD',
	dspDateFormat: 'YYYY-MM-DD',
	globalRegion: {
		name: 'Total Pixels Count',
		colour: '#FFA500',
		title: 'Global'
	},
	combinedRegion: {
		name: 'Combined Total Pixels Count',
		colour: '#FFA500'
	},
	multipleSelectionLimit: 5
};

class IronOre extends Component {
	state = {
		groupType: 'region',
		global: true,
		selectedGrouping: {},
		activeOptions: [],
		selected: 'regions',
		compareCombine: '',
		dateMin: moment.utc('2016-01-01'),
		dateMax: moment.utc(),
		dateFrom: moment.utc(),
		dateTo: moment.utc(),
		smoothingDays: 7,
		isInitDateRange: false,
		shortDateRange: false,
		showDataWarning: false,
		canDownload: false
	};

	async componentDidMount() {
		const authUser = await Auth.currentAuthenticatedUser();
		this.userSub = authUser.attributes['sub'] || '';
		plugins.forEach((plugin) => {
			//register plugin if it is not already registered
			if (!Chart.pluginService._plugins.includes(plugin)) {
				Chart.pluginService.register(plugin);
			}
		});

		this.props.fetchLocations({ commodity: 'fe' });
		// this.props.fetchSites({ commodity: 'fe' });
		this.props.fetchGroups({ commodity: 'fe' });
		this.props.fetchLastUploadDate({ commodity: 'fe' });
	}

	async componentDidUpdate(
		{ lastUploadDate: prevLastUploadDate, locations: prevLocations },
		{
			isInitDateRange: prevIsInitDateRange,
			selected: prevSelected,
			selectedGrouping: prevSelectedGrouping,
			global: prevGlobal
		}
	) {
		const { lastUploadDate, locations } = this.props;
		const { isInitDateRange, selected, selectedGrouping, global } = this.state;
		if (lastUploadDate === '') {
			return;
		}

		//Date setting
		if (lastUploadDate !== prevLastUploadDate) {
			this.updateDateHandling({ lastUploadDate });
		}

		//once date range is set, fetch data
		if (
			(isInitDateRange !== prevIsInitDateRange && locations.regions.length) ||
			(isInitDateRange &&
				locations.regions.length > prevLocations.regions.length)
		) {
			await this.getActualSelectingOptions(selected);
			this.filterData();
		}

		if (
			selected !== prevSelected ||
			selectedGrouping[selected]?.length !==
				prevSelectedGrouping[selected]?.length ||
			global !== prevGlobal
		) {
			this.filterData();
		}
	}

	componentWillUnmount() {
		this.props.resetStockpilesData();
		this.props.resetLocations();
		// this.props.resetSites();
		this.props.resetGroups();
		this.props.resetLastUploadDate();
	}

	async updateDateHandling({ lastUploadDate }) {
		const maxDate = moment.utc(lastUploadDate);

		let downloadState = {};

		let dateState = {
			dateFrom: maxDate.clone().subtract(1, 'y').subtract(1, 'd'),
			dateTo: maxDate.clone().subtract(1, 'd'),
			dateMax: maxDate.clone().subtract(1, 'd')
		};

		this.setState({
			...dateState,
			...downloadState,
			isInitDateRange: true
		});
	}

	// Radio button click for combine/compare
	async onRadioBtnClick(rSelected) {
		this.setState({ ...rSelected });
	}

	async getActualSelectingOptions(filtering) {
		const { locations, groups, lastUploadDate, sites } = this.props;

		let allOptions = [];
		if (filtering === 'regions' || filtering === 'countries') {
			allOptions = locations[filtering].map(({ name, id }) => ({ name, id }));
		} else if (filtering === 'sites') {
			allOptions = sites.map(({ name, id }) => ({ name, id }));
		} else {
			allOptions = groups[filtering?.substring(6)].map(({ name, id }) => ({
				name,
				id
			}));
		}

		if (allOptions.length === 0) return;
		const ids = allOptions.map(({ id }) => id);
		try {
			const data = await fetchStockpilesDataInitial({
				commodity: 'fe',
				grouping: filtering.startsWith('group_') ? 'groups' : `${filtering}`,
				ids,
				startDate: `${moment
					.utc(lastUploadDate)
					.subtract(3, 'months')
					.format(config.apiDateFormat)}`,
				endDate: `${moment.utc(lastUploadDate).format(config.apiDateFormat)}`
			});
			const activeOptionNames = data.singleOutputs
				.filter(({ data }) => data.length > 0)
				.map(({ name }) => name);
			this.setState({
				activeOptions: allOptions.filter(({ name }) =>
					activeOptionNames.includes(name)
				)
			});
		} catch (e) {
			console.log(`An error while getting options for ${filtering}: ${e}`);
		}
	}

	// Global button click
	async onGlobalBtnClick() {
		const { global, selected, selectedGrouping } = this.state;
		let newState = {};
		//reset regions when switching to global, but only for current view
		if (!global) {
			const newSelectedGrouping = { ...selectedGrouping };
			newSelectedGrouping[selected] = [];
			newState = { ...newState, selectedGrouping: newSelectedGrouping };
		}

		//toggle global
		newState = { ...newState, global: !global };

		this.setState(newState);
	}

	// region buttons
	async onRegionBtnClick({ name, val }) {
		const { selected, selectedGrouping } = this.state;
		//existing regions
		const selectedIds = selectedGrouping[selected]?.map(({ id }) => id) || [];
		const oldSelectedCount = selectedIds.length;

		let selectedRegions = [];

		if (selectedIds.includes(val)) {
			selectedRegions = selectedGrouping[selected].filter(
				({ id }) => id !== val
			);
		} else {
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			selectedRegions =
				selectedIds.length === 0
					? [{ id: val, name, colour }]
					: [...selectedGrouping[selected], { id: val, name, colour }];
		}
		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: selectedRegions
		};
		const newSelectedCount = newSelectedGrouping[selected].length;

		const global = newSelectedCount ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global
		};

		if (newSelectedCount <= 1) {
			newState = { ...newState, compareCombine: '' };
		} else if (oldSelectedCount <= 1) {
			//regions count increased
			newState = { ...newState, compareCombine: 'combine' };
		}
		this.setState(newState);
	}

	async onMultipleSelection(vals) {
		const { selected, selectedGrouping } = this.state;

		const oldSelectedIds =
			selectedGrouping[selected]?.map(({ id }) => id) || [];
		const newSelectedIds = vals.map(({ id }) => id);

		let newSelectedOptions = [];
		//removed
		if (oldSelectedIds.length > newSelectedIds.length) {
			newSelectedOptions = selectedGrouping[selected].filter(({ id }) =>
				newSelectedIds.includes(id)
			);
		} else {
			//one option added
			const newAdded = vals.filter(({ id }) => !oldSelectedIds.includes(id));
			const { id, name } = [...newAdded][0];
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			newSelectedOptions =
				oldSelectedIds.length === 0
					? [{ id, name, colour }]
					: [...selectedGrouping[selected], { id, name, colour }];
		}

		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: newSelectedOptions
		};

		const global = newSelectedGrouping[selected]?.length ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global
			// compareCombine: selectedIds.length <= 1 ? '' : compareCombine
		};
		if (newSelectedIds.length <= 1) {
			newState = { ...newState, compareCombine: '' };
		} else if (oldSelectedIds.length <= 1) {
			//options count increased
			newState = { ...newState, compareCombine: 'combine' };
		}
		this.setState(newState);
	}

	// Region toggling for the select all / none button
	async onToggleAllRegions() {
		const { regions, compareCombine } = this.state;
		const { steelPseudoGroups } = this.props;
		//exclude 'globalexchina' from All
		const defaultGroups = steelPseudoGroups.filter(
			(g) => g.type === 'default' && g.key !== 'globalexchina'
		);

		const newRegions =
			regions.length < defaultGroups.length
				? defaultGroups.map((g) => g.key)
				: [];
		const newCompareCombine = regions.length > 1 ? compareCombine : 'combine';
		await this.setState({
			regions: newRegions,
			selected: newRegions,
			compareCombine: newRegions.length ? newCompareCombine : '',
			global: false
		});
		this.filterData();
	}

	// Handle date range selection event
	async onDateRangeSelect({ startDate, endDate }) {
		//startDate, endDate - moment objects
		await this.setState({
			dateFrom: startDate,
			dateTo: endDate,
			shortDateRange: endDate.diff(startDate, 'days') < config.dateRangeDayLimit
		});
		!this.state.shortDateRange && this.filterData();
	}

	// InputSelect for the smoothing selection or group type
	onInputSelect = async ({ target: { value, name } }) => {
		const { selectedGrouping } = this.state;

		if (name === 'smoothingDays') {
			//values inside drop down list are strings
			this.setState({
				[name]: parseInt(value)
			});
			return;
		}

		if (name === 'groupType') {
			let filtering = '';

			if (value === 'region') {
				filtering = 'regions';
			} else if (value === 'country') {
				filtering = 'countries';
			} else if (value === 'site') {
				filtering = 'sites';
			} else {
				filtering = value;
			}

			const global = selectedGrouping[filtering]?.length ? false : true;
			this.setState({
				selected: filtering,
				groupType: value,
				global: global,
				activeOptions: []
			});
			this.getActualSelectingOptions(filtering);
		}
	};

	// Run action creator to fetch smelter data based on filter form state
	filterData() {
		const { global, dateFrom, dateTo, selected, selectedGrouping } = this.state;
		this.props.resetStockpilesData();
		if (global) {
			this.props.fetchStockpilesData({
				commodity: 'fe',
				grouping: 'regions',
				ids: '',
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			});
		} else if (selectedGrouping[selected]?.length > 0) {
			this.props.fetchStockpilesData({
				commodity: 'fe',
				grouping:
					selected === 'regions' ||
					selected === 'countries' ||
					selected === 'sites'
						? selected
						: 'groups',
				ids: selectedGrouping[selected]?.map(({ id }) => id).join(',') || '',
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			});
		}
	}

	getChartType() {
		const { dateFrom, dateTo, showDispersion } = this.state;
		const days = dateTo.diff(dateFrom, 'd');
		const type = showDispersion ? 'a' : 'f';
		let size = '';

		if (days <= 50) {
			size = 'sm';
		} else if (days > 50 && days <= 100) {
			size = 'md';
		} else if (days > 100 && days <= 200) {
			size = 'lg';
		} else if (days > 200) {
			size = 'xl';
		} else if (days > 300) {
			size = 'xxl';
		}
		return { size, type };
	}

	// Build the card header element for display
	buildCardHeader() {
		const { compareCombine, global, groupType } = this.state;

		let selection = 'group';
		if (
			groupType === 'region' ||
			groupType === 'country' ||
			groupType === 'site'
		) {
			selection = groupType;
		}

		let selections = 'groups';
		if (groupType === 'region') {
			selections = 'regions';
		} else if (groupType === 'country') {
			selections = 'countries';
		} else if (groupType === 'site') {
			selections = 'sites';
		}

		let title = 'Total Pixels Count:';
		if (global) {
			title = `${title} ${config.globalRegion.title}`;
		} else if (compareCombine === 'combine') {
			title = `${title} Combine multiple ${selections}`;
		} else if (compareCombine === 'compare') {
			title = `${title} Compare multiple ${selections}`;
		} else {
			title = `${title} Single ${selection}`;
		}

		return (
			<CardHeader>
				<i className="fa fa-line-chart" aria-hidden="true" /> {title}
			</CardHeader>
		);
	}

	buildRegionButton({ id, name, title }) {
		const { selectedGrouping, selected } = this.state;

		return (
			<Fragment key={`region${name}`}>
				<Button
					id={`region_${id}`}
					outline
					size="sm"
					color="secondary"
					onClick={() => this.onRegionBtnClick({ name, val: id })}
					active={selectedGrouping[selected]?.map(({ id }) => id).includes(id)}
					aria-label={title}
					className="light-active-border"
				>
					{title}
				</Button>
			</Fragment>
		);
	}

	buildCsvDownloadButton() {
		const {
			global,
			shortDateRange,
			selected,
			selectedGrouping
			// canDownload
		} = this.state;
		const { ironOreData } = this.props;

		if (
			(global || selectedGrouping[selected]?.length) &&
			!shortDateRange &&
			ironOreData.length !== 0
		) {
			let { csvHeaders, csvData, csvFileName } = this.getCsvDataFromProps();

			return (
				<CSVLink
					id="buttonDownload"
					filename={csvFileName}
					headers={csvHeaders}
					data={csvData}
					className="btn btn-success mb-3 pull-right btn-sm"
					onClick={() => {
						ReactGA.event({
							category: `iron_ore_total_pixels_download`,
							action: 'download',
							label: this.userSub
						});
						return true;
					}}
				>
					<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
					data
				</CSVLink>
			);
		}

		return (
			<Button
				id="buttonDownload"
				color="success"
				disabled
				className="mb-3 pull-right"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
				data
			</Button>
		);
	}

	// Build the filter form
	buildFilterForm() {
		const { groups, lastUploadDate } = this.props;
		const {
			dateMin,
			dateMax,
			dateFrom,
			dateTo,
			compareCombine,
			global,
			groupType,
			// regions,
			selectedGrouping,
			selected,
			activeOptions
		} = this.state;

		return (
			<Form>
				<Container fluid className="px-0 container-width">
					<Row noGutters>
						<Col xs="12" sm="12">
							<Label for="groupType" className="mb-2 mr-3 align-top">
								<span className="sr-only">Geographical filtering type</span>
								<Input
									id="groupType"
									name="groupType"
									type="select"
									bsSize="sm"
									className="pointered"
									defaultValue={groupType}
									onChange={this.onInputSelect}
								>
									<option value="region">Region filtering</option>
									<option value="country">Country filtering</option>
									{Object.keys(groups).map((g) => (
										<option key={`group_${g}`} value={`group_${g}`}>
											{g.charAt(0).toUpperCase() + g.slice(1)} groups filtering
										</option>
									))}
									<option value="site">Site filtering</option>
								</Input>
							</Label>

							<Button
								id="regionGlobal"
								outline
								size="sm"
								color="secondary"
								active={global}
								aria-label="global"
								className="mb-2 light-active-border"
								onClick={() => this.onGlobalBtnClick()}
							>
								Global
							</Button>

							{groupType === 'region' && activeOptions.length > 0 && (
								<ButtonGroup className="mb-2 ml-3">
									{activeOptions.map(({ name, id }) => {
										return this.buildRegionButton({
											id,
											name,
											title: name
										});
									})}
								</ButtonGroup>
							)}

							{groupType.startsWith('group_') && activeOptions.length > 0 && (
								<ButtonGroup className="mb-2 ml-3">
									{activeOptions.map(({ name, id }) => {
										return this.buildRegionButton({
											id,
											name,
											title: name
										});
									})}
								</ButtonGroup>
							)}

							{groupType === 'country' && (
								<Select
									isMulti
									options={activeOptions.map(({ id, name }) => ({
										value: id,
										label: name
									}))}
									isOptionDisabled={(option) =>
										selectedGrouping.countries?.length ===
										config.multipleSelectionLimit
									}
									value={
										activeOptions
											.filter((c) =>
												selectedGrouping.countries
													?.map(({ id }) => id)
													.includes(c.id)
											)
											.map(({ id, name }) => ({ value: id, label: name })) || []
									}
									onChange={(val) =>
										this.onMultipleSelection(
											val?.map(({ value: id, label: name }) => ({
												id,
												name
											})) || []
										)
									}
									className="react-select-container d-inline-block mb-2 ml-3"
									classNamePrefix="react-select"
									placeholder="Select countries..."
									styles={multiSelectStyle}
								/>
							)}
							{groupType === 'site' && (
								<Select
									isMulti
									options={activeOptions.map(({ id, name }) => ({
										value: id,
										label: name
									}))}
									isOptionDisabled={(option) =>
										selectedGrouping.sites?.length ===
										config.multipleSelectionLimit
									}
									value={
										activeOptions
											.filter((s) =>
												selectedGrouping.sites
													?.map(({ id }) => id)
													.includes(s.id)
											)
											.map(({ id, name }) => ({ value: id, label: name })) || []
									}
									onChange={(val) =>
										this.onMultipleSelection(
											val?.map(({ value: id, label: name }) => ({
												id,
												name
											})) || []
										)
									}
									className="react-select-container d-inline-block mb-2 ml-3"
									classNamePrefix="react-select"
									placeholder="Select sites..."
									styles={multiSelectStyle}
								/>
							)}
						</Col>
					</Row>
					<Row noGutters>
						<Col xs="12" sm="12">
							<DateRangePopover
								id="dateSelector"
								startDate={dateFrom}
								endDate={dateTo}
								minDate={dateMin}
								maxDate={dateMax}
								newestDate={lastUploadDate}
								onDateRangeSelected={this.onDateRangeSelect.bind(this)}
							/>
							<ButtonGroup className="mb-3 mr-3 align-bottom">
								<Button
									id="buttonCombine"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'combine' })
									}
									active={compareCombine === 'combine'}
									disabled={global || selectedGrouping[selected]?.length <= 1}
									aria-label="Click to combine smelter data"
								>
									Combine
								</Button>
								<Button
									id="buttonCompare"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'compare' })
									}
									active={compareCombine === 'compare'}
									disabled={global || selectedGrouping[selected]?.length <= 1}
									aria-label="Click to compare smelter data"
								>
									Compare
								</Button>
							</ButtonGroup>
							<Label for="smoothingDays" className="mb-3 mr-2 smoothing-select">
								<span className="sr-only">Smoothing options</span>
								<Input
									id="smoothingDays"
									name="smoothingDays"
									type="select"
									bsSize="sm"
									className="pointered smoothingOptions"
									value={this.state.smoothingDays}
									onChange={this.onInputSelect}
								>
									<option value="0">No data smoothing</option>
									{config.smoothingOptions.map(({ title, days }, idx) => {
										return (
											<option key={`smooth${idx}`} value={days}>
												{title}
											</option>
										);
									})}
								</Input>
							</Label>
							{this.state.canDownload && this.buildCsvDownloadButton()}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	// Build csv data from the props for the download feature
	getCsvDataFromProps() {
		const {
			ironOreData: { singleOutputs = [], accOutputs = [] }
		} = this.props;
		let { dateFrom, dateTo, global } = this.state;
		// Initialise our csv data
		let csvFileName = `iron-ore-data-${dateFrom.format(
			config.apiDateFormat
		)}-${dateTo.format(config.apiDateFormat)}.csv`;
		let csvHeaders = [{ label: 'Date', key: 't' }];
		let csvData = [];

		accOutputs.forEach(({ t, p }, k) => {
			// Add the time column value to the csv data if this is the first iteration
			// or if we've somehow got a frame error from the api data and the row is missing
			if (!csvData[k]) {
				csvData[k] = {
					t
				};
			}

			// Generate the p column names
			let namepkey = global ? `Global Total Pixels` : `Combined Total Pixels`;

			// Add the column names to the csv headers if this is the first data iteration
			if (k === 0) {
				csvHeaders = [
					...csvHeaders,
					...[
						{
							label: namepkey,
							key: namepkey
						}
					]
				];
			}
			// Set the f column on the csv data
			csvData[k][namepkey] = p;
		});

		const dates = csvData.map(({ t }) => t);
		if (dates.length) {
			dates.forEach((t, j) => {
				singleOutputs.forEach(({ name, data }) => {
					// Generate the region-based p and σ column names
					let namepkey = `${name} Total Pixels`;

					if (j === 0) {
						csvHeaders = [
							...csvHeaders,
							...[
								{
									label: namepkey,
									key: namepkey
								}
							]
						];
					}

					csvData[j][namepkey] = data.find((d) => d.t === t)?.p || '';
				});
			});
		} else {
			singleOutputs.forEach(({ name, data }) => {
				data.forEach(({ t, p }, j) => {
					// Add the time column value to the csv data if this is the first region iteration
					// or if we've somehow got a frame error from the api data and the row is missing
					if (!csvData[j]) {
						csvData[j] = {
							t: moment.utc(t).format(config.dspDateFormat)
						};
					}

					// Generate the region-based p and σ column names
					let namepkey = `${name} Total Pixels`;

					// Add the column names to the csv headers if this is the first data iteration
					if (j === 0) {
						csvHeaders = [
							...csvHeaders,
							...[
								{
									label: namepkey,
									key: namepkey
								}
							]
						];
					}

					// Set the f column on the csv data
					csvData[j][namepkey] = p;
				});
			});
		}

		return {
			csvFileName,
			csvHeaders,
			csvData
		};
	}

	buildRegionSelectError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select at least one region.
			</Alert>
		);
	}

	buildShortDateRangeError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select a date range of at least 10 days.
			</Alert>
		);
	}

	buildLoadingMessage() {
		return (
			<Fragment>
				<div className="h6 mb-3">Chart data loading...</div>
				<Progress animated value="100" />
			</Fragment>
		);
	}

	// Apply a moving average smoothing to the data provided
	smoothStockpilesData({ days, data }) {
		let pSmoothedData = data.map(({ p }) => parseInt(p));
		pSmoothedData = ma(pSmoothedData, days);

		return data.map((item, i) => {
			if (i < days - 1) {
				return { ...item, p: null };
			}
			return {
				...item,
				p: pSmoothedData[i]
			};
		});
	}

	buildChartAnnotations() {
		const { steelSmelterUpdates = [] } = this.props;
		const { dateFrom, dateTo } = this.state;
		const totalDays = dateTo.diff(dateFrom, 'days');

		let annotation = {};
		if (steelSmelterUpdates.length > 0) {
			annotation = {
				...annotationsStem,
				annotations: []
			};

			steelSmelterUpdates.forEach(({ detail, update_date }, idx) => {
				let newAnnotation = {
					...annotationStem,
					id: `anno${idx}`,
					value: new Date(update_date),
					label: {
						...annotationStem.label,
						content: detail
					}
				};

				// Position the labels away from the edges
				const pos = Math.floor(
					(moment.utc(update_date).diff(dateFrom, 'days') / totalDays) * 100
				);
				if (pos <= 13) {
					newAnnotation.label.xAdjust = -((detail.length / 2) * 6.1);
				} else if (pos >= 87) {
					newAnnotation.label.xAdjust = (detail.length / 2) * 6.1;
				}

				annotation.annotations.push(newAnnotation);
			});
		}
		return annotation;
	}

	getChartOptions(min = null, max = null) {
		//setting y-axis bounds dynamically for dispersion graph

		const fChartOptionsWithRange =
			min === 0 && max === 0
				? {
						...fChartOptions,
						scales: {
							...fChartOptions.scales,
							yAxes: fChartOptions.scales.yAxes.map((ya) => ({
								...ya,
								ticks: { ...ya.ticks, min: 0 }
							}))
						}
				  }
				: fChartOptions;

		return {
			...fChartOptionsWithRange
		};
	}

	getSelectedDetail(arr = []) {
		const { global, selected, selectedGrouping } = this.state;
		if (arr.length === 1 && global) {
			return [{ key: 'global', ...config.globalRegion }];
		} else if (arr.length === 1 && arr[0] === 'combine') {
			return [{ key: 'combine', ...config.combinedRegion }];
		} else if (arr.length >= 1) {
			return arr.map((a) => ({
				key: a.toLowerCase(),
				title: a,
				name: a,
				colour:
					selectedGrouping[selected].find(({ name }) => name === a)?.colour ||
					''
			}));
		}
	}

	getChartData(dataArr) {
		const { smoothingDays: days, dateFrom, dateTo } = this.state;

		const details = this.getSelectedDetail(dataArr.map(({ name }) => name));

		// Build the datasets by iterating the allRegions array and reducing it with the smelterData compare field,
		// so that the original order of the regions is maintained
		return dataArr.map(({ name, data }) => {
			// Smooth the data if required
			if (days !== 0) {
				data = this.smoothStockpilesData({
					days,
					data
				});
			}

			const detail = details.find(
				(d) => d.key.toLowerCase() === name.toLowerCase()
			);
			//filled point for combine
			const pCombinePointBackgroundColour =
				name === 'combine' ? { pointBackgroundColor: detail.colour } : {};

			//fStyle will be added to existing active capacity style in configuration
			const pStyle = {
				label: detail.name,
				borderColor: detail.colour,
				...pCombinePointBackgroundColour
			};

			if (data.length === 0) {
				return {
					data: [
						{
							t: moment.utc(dateFrom).format(config.dspDateFormat),
							p: ''
						},
						{
							t: moment.utc(dateTo).format(config.dspDateFormat),
							p: ''
						}
					],
					...genChartStyle(this.getChartType()),
					...pStyle
				};
			}
			return {
				data: data.map(({ t, p }) => {
					return {
						t: moment.utc(t).format(config.dspDateFormat),
						y: p
					};
				}),
				...genChartStyle(this.getChartType()),
				...pStyle
			};
		});
	}

	getRangeY(dataset) {
		const data = dataset
			.map((ds) => ds.data.map((d) => d.y))
			.reduce((arr, elem) => arr.concat(elem));
		//smoothing adds some nulls at the beginning of array, remove nulls
		const dataWithoutNull = data.filter((d) => d !== null);
		const maxValue = Math.max(...dataWithoutNull);
		const minValue = Math.min(...dataWithoutNull);
		return { minY: minValue, maxY: maxValue };
	}

	// Individual data lines shown for multiple regions
	buildLineChart() {
		const { ironOreData } = this.props;
		const { compareCombine, global, selected, selectedGrouping } = this.state;
		const isCombined =
			compareCombine === 'combine' &&
			!global &&
			selectedGrouping[selected]?.length > 1;

		let apiData =
			isCombined || global ? ironOreData.accOutputs : ironOreData.singleOutputs;
		//compare data length gives info whether API response is received, combined data length doesn't contain that information
		if (!apiData || !apiData.length) {
			return this.buildLoadingMessage();
		}

		if (isCombined) {
			apiData = [{ name: 'combine', data: ironOreData.accOutputs }];
		} else if (global) {
			apiData = [{ name: 'Global', data: ironOreData.accOutputs }];
		}
		const datasets = this.getChartData(apiData);

		const { minY, maxY } = this.getRangeY(datasets);

		return (
			<Card className="mb-3">
				{this.buildCardHeader()}
				<CardBody>
					<Container
						fluid
						className="pl-0 container-width"
						style={{ paddingRight: '21px' }}
					>
						<Row noGutters className="mt-0">
							<Col xs="12" sm="12">
								<Line
									data={{ datasets }}
									options={this.getChartOptions(minY, maxY)}
								/>
							</Col>
						</Row>
					</Container>
				</CardBody>
			</Card>
		);
	}

	render() {
		let { global, shortDateRange, selectedGrouping, selected } = this.state;
		return (
			<DocumentTitle title="GAINS | Stockpiles Iron Ore Data">
				<div className="content-wrapper">
					<Container fluid>
						{/* Help, crumbs and the filter form */}
						<Crumbs type="fe" path={[{ title: 'Iron Ore Data' }]} />
						{this.buildFilterForm()}

						{/* Error messages */}
						{shortDateRange && this.buildShortDateRangeError()}
						{!shortDateRange &&
							!global &&
							!selectedGrouping[selected]?.length &&
							this.buildRegionSelectError()}

						{/* Different chart types */}
						{!shortDateRange &&
							((!global && selectedGrouping[selected]?.length > 0) ||
								(global && !selectedGrouping[selected]?.length)) &&
							this.buildLineChart()}
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({
	lastUploadDate,
	locations,
	sites,
	groups,
	stockpilesData
}) => {
	return {
		lastUploadDate,
		locations,
		sites,
		groups,
		ironOreData: stockpilesData
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchLastUploadDate: (filterData) =>
		dispatch(fetchLastUploadDate(filterData)),
	resetLastUploadDate: () => dispatch(resetLastUploadDate()),
	fetchStockpilesData: (filterData) =>
		dispatch(fetchStockpilesData(filterData)),
	resetStockpilesData: () => dispatch(resetStockpilesData()),
	fetchLocations: (filterData) => dispatch(fetchLocations(filterData)),
	resetLocations: () => dispatch(resetLocations()),
	fetchSites: (filterData) => dispatch(fetchSites(filterData)),
	resetSites: () => dispatch(resetSites()),
	fetchGroups: (filterData) => dispatch(fetchGroups(filterData)),
	resetGroups: () => dispatch(resetGroups())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(IronOre));
