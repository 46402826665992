import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Auth } from 'aws-amplify';
import DocumentTitle from 'react-document-title';
import {
	Alert,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row,
	Progress
	// UncontrolledTooltip
} from 'reactstrap';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga4';
import { ma } from 'moving-averages';

import {
	fetchLastUploadDate,
	resetLastUploadDate,
	fetchSteelSmelterData,
	resetSteelSmelterData,
	fetchLocations,
	resetLocations,
	fetchGroups,
	resetGroups
} from 'actions';
import {
	colourPalette,
	fChartOptions,
	// aChartOptions,
	// aThresholdStyle,
	genChartStyle,
	annotationsStem,
	annotationStem,
	plugins
} from './chartConfig';
import { Crumbs } from 'containers';
import { DateRangePopover } from 'components';
import MultiSelect from 'components/multiselect/MultiSelect';

import './Smelters.scss';

const config = {
	dateRangeDayLimit: 10,
	smoothingOptions: [
		{
			title: '2 day moving average',
			days: 2
		},
		{
			title: '4 day moving average',
			days: 4
		},
		{
			title: '7 day moving average',
			days: 7
		},
		{
			title: '14 day moving average',
			days: 14
		},
		{
			title: '21 day moving average',
			days: 21
		},
		{
			title: '30 day moving average',
			days: 30
		}
	],
	apiDateFormat: 'YYYYMMDD',
	dspDateFormat: 'YYYY-MM-DD',
	globalRegion: {
		name: 'Inactive Capacity Index',
		colour: '#FFA500',
		title: 'Global'
	},
	combinedRegion: {
		name: 'Combined index',
		colour: '#FFA500'
	},
	multipleSelectionLimit: 5
};

class SteelSmelters extends Component {
	state = {
		groupType: 'region',
		global: true,
		selectedGrouping: {},
		selected: 'regions',
		compareCombine: '',
		// showDispersion: false,
		showActivity: true,
		dateMin: moment.utc('2016-03-01'),
		dateMax: moment.utc(),
		dateFrom: moment.utc(),
		dateTo: moment.utc(),
		smoothingDays: 7,
		isInitDateRange: false,
		shortDateRange: false,
		showDataWarning: false,
		canDownload: false,
		plantTypes: [],
		selectedPlantType: '',
		dataByPlant: { combined: '', bfbof: '', eaf: '' }
	};

	async componentDidMount() {
		plugins.forEach((plugin) => {
			//register plugin if it is not already registered
			if (!Chart.pluginService._plugins.includes(plugin)) {
				Chart.pluginService.register(plugin);
			}
		});
		const authUser = await Auth.currentAuthenticatedUser();
		this.userSub = authUser.attributes['sub'] || '';

		this.props.fetchLocations({ commodity: 'steel' });
		this.props.fetchGroups({ commodity: 'steel' });
		this.props.fetchLastUploadDate({ commodity: 'steel' });
	}

	componentDidUpdate(
		{
			lastUploadDate: prevLastUploadDate,
			steelSmelterData: prevSteelSmelterData,
			locations: { regions: prevRegions }
		},
		{
			isInitDateRange: prevIsInitDateRange,
			dateFrom: prevDateFrom,
			dateTo: prevDateTo,
			selected: prevSelected,
			selectedGrouping: prevSelectedGrouping,
			global: prevGlobal,
			compareCombine: prevCompareCombine,
			selectedPlantType: prevSelectedPlantType,
			showActivity: prevShowActivity
		}
	) {
		const {
			lastUploadDate,
			steelSmelterData,
			locations: { regions, countries }
		} = this.props;
		const {
			isInitDateRange,
			dateFrom,
			dateTo,
			selected,
			selectedGrouping,
			global,
			selectedPlantType,
			dataByPlant,
			plantTypes,
			showActivity
		} = this.state;

		//fetchSteelNewestDate requested in ComponentDidMount(), ensure newestDate is received
		if (lastUploadDate === '') {
			return;
		}

		//Date setting
		if (lastUploadDate !== prevLastUploadDate) {
			this.updateDateHandling({ lastUploadDate });
		}

		if (regions.length === 1 && regions.length !== prevRegions.length) {
			this.setState(
				{
					groupType: 'country',
					selected: 'countries',
					global: false
				},
				() => this.handleMultipleSelections(countries)
			);
			return;
		}

		//once date range is set, fetch data
		if (
			(isInitDateRange !== prevIsInitDateRange && regions.length) ||
			(isInitDateRange && regions.length !== prevRegions.length)
		) {
			this.filterData();
		}

		if (
			(isInitDateRange === prevIsInitDateRange &&
				(prevDateFrom !== dateFrom || prevDateTo !== dateTo)) ||
			selected !== prevSelected ||
			selectedGrouping[selected]?.length !==
				prevSelectedGrouping[selected]?.length ||
			((global || selectedGrouping[selected]?.length === 1) &&
				selectedPlantType !== prevSelectedPlantType &&
				selectedPlantType &&
				!dataByPlant[selectedPlantType]) ||
			(selectedGrouping[selected]?.length >= 1 &&
				selectedPlantType !== prevSelectedPlantType) ||
			global !== prevGlobal
		) {
			this.filterData();
		}
		if (
			(Object.keys(steelSmelterData).length &&
				!Object.keys(prevSteelSmelterData).length &&
				(global || selectedGrouping[selected]?.length)) ||
			showActivity !== prevShowActivity
		) {
			if (
				selectedGrouping[selected]?.length >= 1 &&
				plantTypes.length &&
				selectedGrouping[selected].map(({ name }) => name).length >
					steelSmelterData?.singleIdx.map(({ name }) => name).length
			) {
				toastr.warning(
					`${showActivity ? 'Activity' : 'Inactive Capacity'}  `,
					`No data for ${
						selectedPlantType === 'eaf' ? 'EAF' : 'BF/BOF'
					} in ${selectedGrouping[selected]
						.map(({ name }) => name)
						.filter(
							(n) =>
								!steelSmelterData?.singleIdx.map(({ name }) => name).includes(n)
						)
						.join(', ')}
				`
				);
			}
			if (selectedGrouping[selected]?.length > 1) {
				return;
			}
			selectedPlantType
				? global
					? this.setState({
							dataByPlant: {
								...dataByPlant,
								[selectedPlantType]: steelSmelterData.combinedIdx
							}
					  })
					: selectedGrouping[selected]?.length === 1 &&
					  this.setState({
							dataByPlant: {
								...dataByPlant,
								[selectedPlantType]: steelSmelterData.singleIdx[0]?.data || []
							}
					  })
				: global
				? this.setState({
						dataByPlant: {
							...dataByPlant,
							combined: steelSmelterData.combinedIdx
						}
				  })
				: selectedGrouping[selected]?.length === 1 &&
				  this.setState({
						dataByPlant: {
							...dataByPlant,
							combined: steelSmelterData.singleIdx[0]?.data || []
						},
						selectedPlantType:
							!plantTypes.length ||
							plantTypes.every((pt) => dataByPlant[pt]) === true
								? selectedPlantType
								: plantTypes.find((pt) => dataByPlant[pt] === '')
				  });
		}
	}

	componentWillUnmount() {
		this.props.resetSteelSmelterData();
		this.props.resetLocations();
		this.props.resetGroups();
		this.props.resetLastUploadDate();
	}

	async updateDateHandling({ lastUploadDate }) {
		const maxDate = moment.utc(lastUploadDate);

		let downloadState = {};

		//for enterprise user, savant-premium-plus, savant-premium
		let dateState = {
			dateFrom: maxDate.clone().subtract(1, 'y').subtract(1, 'd'),
			dateTo: maxDate.clone().subtract(1, 'd'),
			dateMax: maxDate.clone().subtract(1, 'd')
		};

		this.setState({
			...dateState,
			...downloadState,
			isInitDateRange: true
		});
	}

	// Radio button click for combine/compare
	async onRadioBtnClick(rSelected) {
		this.setState({ ...rSelected });
	}

	toggleIndex() {
		// const { showDispersion } = this.state;
		// this.setState({ showDispersion: !showDispersion });
		const { showActivity } = this.state;
		this.setState({ showActivity: !showActivity });
	}
	// Global button click
	async onGlobalBtnClick() {
		const { global, selected, selectedGrouping } = this.state;
		let newState = {};
		//reset regions when switching to global, but only for current view
		if (!global) {
			const newSelectedGrouping = { ...selectedGrouping };
			newSelectedGrouping[selected] = [];
			newState = { ...newState, selectedGrouping: newSelectedGrouping };
		}

		//toggle global
		newState = {
			...newState,
			global: !global,
			selectedPlantType: '',
			plantTypes: [],
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			compareCombine: ''
		};

		this.setState(newState);
	}

	// region buttons
	async onRegionBtnClick({ name, val }) {
		const { selected, selectedGrouping, selectedPlantType, plantTypes } =
			this.state;
		//existing regions
		const selectedIds = selectedGrouping[selected]?.map(({ id }) => id) || [];
		const oldSelectedCount = selectedIds.length;

		let selectedRegions = [];

		if (selectedIds.includes(val)) {
			selectedRegions = selectedGrouping[selected].filter(
				({ id }) => id !== val
			);
		} else {
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			selectedRegions =
				selectedIds.length === 0
					? [{ id: val, name, colour }]
					: [...selectedGrouping[selected], { id: val, name, colour }];
		}
		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: selectedRegions
		};
		const newSelectedCount = newSelectedGrouping[selected].length;

		const global = newSelectedCount ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global,
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			plantTypes: newSelectedCount && plantTypes.length <= 1 ? plantTypes : [],
			selectedPlantType:
				newSelectedCount && plantTypes.length <= 1 ? selectedPlantType : ''
		};

		if (newSelectedCount <= 1) {
			newState = {
				...newState,
				compareCombine: ''
			};
		} else if (oldSelectedCount <= 1) {
			//regions count increased
			newState = { ...newState, compareCombine: 'combine' };
		}
		this.setState(newState);
	}

	async onPlantBtnClick({ key }) {
		const {
			plantTypes,
			dataByPlant,
			selectedGrouping,
			selected,
			compareCombine
		} = this.state;

		if (plantTypes.includes(key)) {
			const newPlantTypes = plantTypes.filter((pt) => pt !== key);
			this.setState({
				plantTypes: newPlantTypes,
				selectedPlantType: newPlantTypes.length ? newPlantTypes[0] : '',
				compareCombine:
					selectedGrouping[selected]?.length > 1 ? compareCombine : ''
			});
		} else if (selectedGrouping[selected]?.map(({ id }) => id).length > 1) {
			this.setState({
				plantTypes: [key],
				selectedPlantType: key
			});
		} else {
			const newPlantTypes = [...plantTypes, key];

			this.setState({
				compareCombine: newPlantTypes.length <= 1 ? '' : 'combine',
				selectedPlantType:
					newPlantTypes.length > 1 && dataByPlant.combined?.length === 0
						? ''
						: key,
				plantTypes: newPlantTypes
			});
		}
	}

	handleMultipleSelections = async (vals) => {
		const { selected, selectedGrouping, selectedPlantType, plantTypes } =
			this.state;
		const oldSelectedIds =
			selectedGrouping[selected]?.map(({ id }) => id) || [];
		const newSelectedIds = vals.map(({ id }) => id);

		let newSelectedOptions = [];
		//removed
		if (oldSelectedIds.length > newSelectedIds.length) {
			newSelectedOptions = selectedGrouping[selected].filter(({ id }) =>
				newSelectedIds.includes(id)
			);
		} else {
			//one option added
			const newAdded = vals.filter(({ id }) => !oldSelectedIds.includes(id));
			const { id, name } = [...newAdded][0];
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			newSelectedOptions =
				oldSelectedIds.length === 0
					? [{ id, name, colour }]
					: [...selectedGrouping[selected], { id, name, colour }];
		}

		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: newSelectedOptions
		};
		const newSelectedCount = newSelectedGrouping[selected].length;
		const global = newSelectedCount ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global,
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			plantTypes: newSelectedCount && plantTypes.length <= 1 ? plantTypes : [],
			selectedPlantType:
				newSelectedCount && plantTypes.length <= 1 ? selectedPlantType : ''
			// compareCombine: selectedIds.length <= 1 ? '' : compareCombine
		};
		if (newSelectedCount <= 1) {
			newState = { ...newState, compareCombine: '' };
		} else if (oldSelectedIds.length <= 1) {
			//options count increased
			newState = { ...newState, compareCombine: 'combine' };
		}
		this.setState(newState);
	};

	// Region toggling for the select all / none button
	async onToggleAllRegions() {
		const { selectedGrouping, compareCombine, plantTypes, selectedPlantType } =
			this.state;
		const { locations } = this.props;
		const { regions } = locations;

		if (!selectedGrouping.hasOwnProperty(['regions'])) {
			selectedGrouping['regions'] = [];
		}

		let selectedRegions = [];
		if (selectedGrouping['regions'].length < regions.length) {
			const newRegions = regions.filter(
				({ id }) =>
					!selectedGrouping['regions'].map(({ id }) => id).includes(id)
			);
			const selectedColours =
				selectedGrouping['regions']?.map(({ colour }) => colour) || [];
			const newColours = colourPalette.filter(
				(c) => !selectedColours.includes(c)
			);
			newRegions.forEach((r, i) => (r.colour = newColours[i]));
			selectedRegions = [...selectedGrouping['regions'], ...newRegions];
		}
		const global = selectedRegions.length === 0;
		const newSelectedGrouping = {
			...selectedGrouping,
			regions: selectedRegions
		};
		const newCompareCombine =
			selectedGrouping['regions'].length > 1 ? compareCombine : 'combine';

		await this.setState({
			selectedGrouping: newSelectedGrouping,
			compareCombine: newSelectedGrouping['regions'].length
				? newCompareCombine
				: '',
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			plantTypes:
				selectedRegions.length && plantTypes.length <= 1 ? plantTypes : [],
			selectedPlantType:
				selectedRegions.length && plantTypes.length <= 1
					? selectedPlantType
					: '',
			global
		});
		// this.filterData();
	}

	// Handle date range selection event
	async onDateRangeSelect({ startDate, endDate, selectedGrouping, selected }) {
		//startDate, endDate - moment objects
		const newPlantState =
			global || selectedGrouping[selected]?.length === 1
				? {
						plantTypes: [],
						selectedPlantType: '',
						dataByPlant: { combined: '', bfbof: '', eaf: '' },
						compareCombine: ''
				  }
				: {};

		this.setState({
			dateFrom: startDate,
			dateTo: endDate,
			shortDateRange:
				endDate.diff(startDate, 'days') < config.dateRangeDayLimit,
			...newPlantState
		});

		// !this.state.shortDateRange && this.filterData();
	}

	//sort plain array of regions' names or array of region objects
	sortRegions = (regions) => {
		// const { steelPseudoGroups } = this.props;
		// const { groupType } = this.state;
		// if (!regions.length) {
		// 	return regions;
		// }
		// const regionOrder = steelPseudoGroups
		// 	.filter((g) => g.type === groupType)
		// 	.map((g) => g.key);
		// return regionOrder.reduce((acc, r) => {
		// 	let filtered = [];
		// 	filtered = regions.filter((elem) => {
		// 		return Object.keys(elem).length && Object.keys(elem).includes('value')
		// 			? elem['value'] === r
		// 			: elem === r;
		// 	});
		// 	if (filtered.length) {
		// 		acc.push(filtered[0]);
		// 	}
		// 	return acc;
		// }, []);
	};

	// InputSelect for the smoothing selection or group type
	onInputSelect = async ({ target: { value, name } }) => {
		const { selectedGrouping } = this.state;

		if (name === 'smoothingDays') {
			//values inside drop down list are strings
			this.setState({
				[name]: parseInt(value)
			});
			return;
		}
		if (name === 'groupType') {
			let filtering = '';

			if (value === 'region') {
				filtering = 'regions';
			} else if (value === 'country') {
				filtering = 'countries';
			} else {
				filtering = value;
			}

			const global = selectedGrouping[filtering]?.length ? false : true;
			this.setState({
				plantTypes: [],
				selectedPlantType: '',
				dataByPlant: { combined: '', bfbof: '', eaf: '' },
				selected: filtering,
				groupType: value,
				compareCombine: '',
				global: global
			});
		}
	};

	// Run action creator to fetch smelter data based on filter form state
	filterData() {
		const {
			global,
			dateFrom,
			dateTo,
			selected,
			selectedGrouping,
			selectedPlantType
		} = this.state;
		this.props.resetSteelSmelterData();
		if (global) {
			this.props.fetchSteelSmelterData({
				grouping: 'regions',
				ids: '',
				plant: selectedPlantType,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			});
		} else if (selectedGrouping[selected]?.length > 0) {
			this.props.fetchSteelSmelterData({
				grouping:
					selected === 'regions' || selected === 'countries'
						? selected
						: 'groups',
				ids: selectedGrouping[selected]?.map(({ id }) => id).join(',') || '',
				plant: selectedPlantType,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			});
		}
	}

	getChartType() {
		const { dateFrom, dateTo, showDispersion } = this.state;
		const days = dateTo.diff(dateFrom, 'd');
		const type = showDispersion ? 'a' : 'f';
		let size = '';

		if (days <= 50) {
			size = 'sm';
		} else if (days > 50 && days <= 100) {
			size = 'md';
		} else if (days > 100 && days <= 200) {
			size = 'lg';
		} else if (days > 200) {
			size = 'xl';
		} else if (days > 300) {
			size = 'xxl';
		}
		return { size, type };
	}

	// Build the card header element for display
	buildCardHeader() {
		const {
			compareCombine,
			global,
			groupType,
			showActivity,
			plantTypes,
			selectedGrouping,
			selected
		} = this.state;

		const groupName = groupType.substring(6);
		let selection = groupType;
		if (groupType.startsWith('group_')) {
			selection = groupName;
		}

		let selections = 'groups';
		if (groupType === 'region') {
			selections = 'regions';
		} else if (groupType === 'country') {
			selections = 'countries';
		} else if (groupType.startsWith('group_')) {
			selections = `${groupName} ${selections}`;
		}

		let title = showActivity ? 'Activity:' : 'Inactive Capacity:';
		if (global) {
			title = `${title} ${config.globalRegion.title}`;
		} else if (
			compareCombine === 'combine' &&
			selectedGrouping[selected]?.length > 1
		) {
			title = `${title} Combine multiple ${selections}`;
		} else if (
			compareCombine === 'compare' &&
			selectedGrouping[selected]?.length > 1
		) {
			title = `${title} Compare multiple ${selections}`;
		} else {
			title = `${title} Single ${selection}`;
		}
		title = `${title} ${plantTypes.length ? '-' : ''} ${plantTypes
			.map((pt) => (pt === 'eaf' ? 'EAF' : 'BF/BOF'))
			.join(', ')}`;
		return (
			<CardHeader>
				<i className="fa fa-line-chart" aria-hidden="true" /> {title}
			</CardHeader>
		);
	}

	buildRegionButton({ id, name, title }) {
		const { selectedGrouping, selected } = this.state;

		return (
			<Fragment key={`region${name}`}>
				<Button
					id={`region_${id}`}
					outline
					size="sm"
					color="secondary"
					onClick={() => this.onRegionBtnClick({ name, val: id })}
					active={selectedGrouping[selected]?.map(({ id }) => id).includes(id)}
					aria-label={title}
					className="light-active-border text-nowrap"
				>
					{title}
				</Button>
			</Fragment>
		);
	}

	buildPlantButton({ id, name, title }) {
		const {
			plantTypes,
			dataByPlant,
			selectedPlantType,
			selectedGrouping,
			selected,
			global
		} = this.state;
		const key = name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
		return (
			<Fragment key={`plant${name}`}>
				<Button
					id={`plant_${id}`}
					outline
					size="sm"
					color="secondary"
					onClick={() => this.onPlantBtnClick({ key, id })}
					active={plantTypes.includes(key)}
					disabled={
						((dataByPlant.combined === '' && plantTypes.length !== 1) ||
							(plantTypes.length === 1 &&
								dataByPlant[selectedPlantType] === '' &&
								key !== selectedPlantType)) &&
						(global || selectedGrouping[selected]?.length === 1)
					}
					aria-label={title}
					className="light-active-border"
				>
					{title}
				</Button>
			</Fragment>
		);
	}

	buildCsvDownloadButton() {
		const { global, shortDateRange, selected, selectedGrouping, canDownload } =
			this.state;
		const { steelSmelterData } = this.props;

		if (
			(global || selectedGrouping[selected]?.length) &&
			!shortDateRange &&
			steelSmelterData.length !== 0 &&
			canDownload
		) {
			let { csvHeaders, csvData, csvFileName } = this.getCsvDataFromProps();

			return (
				<CSVLink
					id="buttonDownload"
					filename={csvFileName}
					headers={csvHeaders}
					data={csvData}
					className="btn btn-success mb-3 pull-right btn-sm"
					onClick={() => {
						ReactGA.event({
							category: 'steel_index_data_download',
							action: 'download',
							label: this.userSub
						});

						return true;
					}}
				>
					<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
					data
				</CSVLink>
			);
		}

		return (
			<Button
				id="buttonDownload"
				color="success"
				disabled
				className="mb-3 pull-right"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
				data
			</Button>
		);
	}

	// Build the filter form
	buildFilterForm() {
		const { locations, groups, lastUploadDate } = this.props;
		const {
			dateMin,
			dateMax,
			dateFrom,
			dateTo,
			compareCombine,
			// showDispersion,
			showActivity,
			global,
			groupType,
			// regions,
			selectedGrouping,
			selected,
			plantTypes,
			dataByPlant
		} = this.state;

		const groupName = groupType.substring(6);

		const { regions, countries } = locations;
		const sortedRegions = regions.sort((r1, r2) =>
			r1.name > r2.name ? 1 : -1
		);
		const regionToggleHidden = regions.length < 2;
		const regionAllCopy =
			selectedGrouping['regions']?.length === regions.length ? 'None' : 'All';

		return (
			<Form>
				<Container fluid className="px-0 container-width">
					<Row noGutters>
						<Col xs="12" sm="12">
							<Label for="groupType" className="mb-2 mr-3 align-top">
								<span className="sr-only">Geographical filtering type</span>
								<Input
									id="groupType"
									name="groupType"
									type="select"
									bsSize="sm"
									className="pointered"
									defaultValue={groupType}
									onChange={this.onInputSelect}
								>
									{regions.length !== 1 && (
										<option value="region">Region filtering</option>
									)}
									<option value="country">Country filtering</option>
									{Object.keys(groups)
										.filter((g) => g !== 'plant')
										.map((g) => (
											<option key={`group_${g}`} value={`group_${g}`}>
												{g.charAt(0).toUpperCase() + g.slice(1)} filtering
											</option>
										))}
								</Input>
							</Label>

							{regions.length > 1 && (
								<Button
									id="regionGlobal"
									outline
									size="sm"
									color="secondary"
									active={global}
									aria-label="global"
									className="mb-2 light-active-border"
									onClick={() => this.onGlobalBtnClick()}
								>
									Global
								</Button>
							)}

							{groupType === 'region' && regions.length > 0 && (
								<ButtonGroup className="mb-2 ml-3">
									{sortedRegions.map(({ name, id }) => {
										return this.buildRegionButton({
											id,
											name,
											title: name
										});
									})}
								</ButtonGroup>
							)}

							{groupType === 'region' && (
								<Button
									id="regionAll"
									color="primary"
									size="sm"
									onClick={() => this.onToggleAllRegions()}
									className="mb-2 ml-1"
									style={{ width: '54px' }}
									disabled={global}
									hidden={regionToggleHidden}
								>
									{regionAllCopy}
								</Button>
							)}
							{groupType.startsWith('group_') &&
								groups[groupName].length > 0 &&
								groups[groupName].length <= 12 && (
									<ButtonGroup className="mb-2 ml-3">
										{groups[groupName].map(({ name, id }) => {
											return this.buildRegionButton({
												id,
												name,
												title: name
											});
										})}
									</ButtonGroup>
								)}
							{groupType.startsWith('group_') &&
								groups[groupName].length > 12 && (
									<MultiSelect
										options={groups[groupName]}
										values={selectedGrouping[groupType]}
										limit={config.multipleSelectionLimit}
										onMultipleSelection={this.handleMultipleSelections}
									/>
								)}
							{groupType === 'country' && (
								<MultiSelect
									options={countries}
									values={selectedGrouping.countries}
									limit={config.multipleSelectionLimit}
									onMultipleSelection={this.handleMultipleSelections}
								/>
							)}
							{(groupType === 'region' ||
								groupType === 'country' ||
								groupType.startsWith('group_')) && (
								<ButtonGroup className="mb-2 ml-5">
									{['BF/BOF', 'EAF'].map((name, i) => {
										return this.buildPlantButton({
											id: i,
											name,
											title: name
										});
									})}
								</ButtonGroup>
							)}
						</Col>
					</Row>
					<Row noGutters>
						<Col xs="12" sm="12">
							<DateRangePopover
								id="dateSelector"
								startDate={dateFrom}
								endDate={dateTo}
								minDate={dateMin}
								maxDate={dateMax}
								newestDate={lastUploadDate}
								onDateRangeSelected={this.onDateRangeSelect.bind(this)}
							/>
							<ButtonGroup className="mb-3 mr-3 align-bottom">
								<Button
									id="buttonCombine"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'combine' })
									}
									active={compareCombine === 'combine'}
									disabled={
										(plantTypes.length <= 1 &&
											(global || selectedGrouping[selected]?.length === 1)) ||
										(!global && selectedGrouping[selected]?.length < 1)
									}
									aria-label="Click to combine smelter data"
								>
									Combine
								</Button>
								<Button
									id="buttonCompare"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'compare' })
									}
									active={compareCombine === 'compare'}
									disabled={
										// plantTypes.length <= 1 &&
										// (global || selectedGrouping[selected]?.length <= 1)
										(global || selectedGrouping[selected]?.length <= 1) &&
										(plantTypes.length <= 1 ||
											(plantTypes.length >= 1 &&
												plantTypes.some((pt) => !dataByPlant[pt]?.length)))
									}
									aria-label="Click to compare smelter data"
								>
									Compare
								</Button>
							</ButtonGroup>
							<Button
								id="buttonCapacityDispersion"
								className="mb-3 mr-3 align-bottom"
								outline
								size="sm"
								color="secondary"
								onClick={() => this.toggleIndex()}
								disabled={
									(!global && !selectedGrouping[selected]?.length) ||
									!Object.keys(this.props.steelSmelterData).length
								}
								aria-label="Click to switch between Capacity Index and Activity"
							>
								{showActivity ? 'Inactive Capacity' : 'Activity'}
							</Button>
							<Label for="smoothingDays" className="mb-3 mr-2 smoothing-select">
								<span className="sr-only">Smoothing options</span>
								<Input
									id="smoothingDays"
									name="smoothingDays"
									type="select"
									bsSize="sm"
									className="pointered smoothingOptions"
									value={this.state.smoothingDays}
									onChange={this.onInputSelect}
								>
									<option value="0">No data smoothing</option>
									{config.smoothingOptions.map(({ title, days }, idx) => {
										return (
											<option key={`smooth${idx}`} value={days}>
												{title}
											</option>
										);
									})}
								</Input>
							</Label>
							{this.state.canDownload && this.buildCsvDownloadButton()}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	// Build csv data from the props for the download feature
	getCsvDataFromProps() {
		const {
			steelSmelterData: { singleIdx = [], combinedIdx = [] }
		} = this.props;
		let {
			dateFrom,
			dateTo,
			global,
			dataByPlant,
			plantTypes,
			selectedGrouping,
			selected
		} = this.state;

		let plantTitle = '';
		plantTypes.forEach((pt) => {
			plantTitle = plantTitle + pt + '-';
		});
		let groupingTitle = '';
		if (global) {
			groupingTitle = 'global-';
		} else {
			selectedGrouping[selected].forEach(({ name }) => {
				groupingTitle =
					groupingTitle + name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + '-';
			});
		}
		const fileTitle = `${groupingTitle}${plantTitle}`;

		// Initialise our csv data
		let csvFileName = `${fileTitle}smelter-data-${dateFrom.format(
			config.apiDateFormat
		)}-${dateTo.format(config.apiDateFormat)}.csv`;
		let csvHeaders = [{ label: 'Date', key: 't' }];
		let csvData = [];

		if (global || selectedGrouping[selected]?.length === 1) {
			const plantKeys = Object.keys(dataByPlant).filter(
				(pk) => pk !== 'combined'
			);

			plantKeys.forEach(
				(pk) =>
					plantTypes.includes(pk) &&
					dataByPlant[pk]?.length &&
					dataByPlant[pk].forEach(({ t, f, a }, j) => {
						// Add the time column value to the csv data if this is the first region iteration
						// or if we've somehow got a frame error from the api data and the row is missing
						if (!csvData[j]) {
							csvData[j] = {
								t: moment.utc(t).format(config.dspDateFormat)
							};
						}
						// Generate the region-based f and σ column names
						const title = pk === 'eaf' ? 'EAF' : 'BF/BOF';
						let namefkey = `${title} Inactive Capacity`;
						// let nameakey = `${name} Activity Dispersion`;
						let nameakey = `${title} Activity`;

						// Add the column names to the csv headers if this is the first data iteration
						if (j === 0) {
							csvHeaders = [
								...csvHeaders,
								...[
									{
										label: nameakey,
										key: nameakey
									},
									{
										label: namefkey,
										key: namefkey
									}
								]
							];
						}

						// Set the f column on the csv data
						csvData[j][nameakey] = a;
						csvData[j][namefkey] = f;
					})
			);

			dataByPlant.combined?.length &&
				dataByPlant.combined.forEach(({ t, f, a }, k) => {
					// Add the time column value to the csv data if this is the first iteration
					// or if we've somehow got a frame error from the api data and the row is missing
					if (!csvData[k]) {
						csvData[k] = {
							t: moment.utc(t).format(config.dspDateFormat)
						};
					}
					// Generate the f column names
					let namefkey = `Inactive Capacity`;
					let nameakey = `Activity`;
					// Add the column names to the csv headers if this is the first data iteration
					if (k === 0) {
						csvHeaders = [
							...csvHeaders,
							...[
								{
									label: nameakey,
									key: nameakey
								},
								{
									label: namefkey,
									key: namefkey
								}
							]
						];
					}
					// Set the f column on the csv data
					csvData[k][namefkey] = f;
					csvData[k][nameakey] = a;
				});
		} else {
			singleIdx.forEach(({ name, data }) => {
				data.forEach(({ t, f, a }, j) => {
					// Add the time column value to the csv data if this is the first region iteration
					// or if we've somehow got a frame error from the api data and the row is missing
					if (!csvData[j]) {
						csvData[j] = {
							t: moment.utc(t).format(config.dspDateFormat)
						};
					}

					// Generate the region-based f and σ column names
					let namefkey = `${name} Inactive Capacity`;
					// let nameakey = `${name} Activity Dispersion`;
					let nameakey = `${name} Activity`;

					// Add the column names to the csv headers if this is the first data iteration
					if (j === 0) {
						csvHeaders = [
							...csvHeaders,
							...[
								{
									label: nameakey,
									key: nameakey
								},
								{
									label: namefkey,
									key: namefkey
								}
							]
						];
					}

					// Set the f column on the csv data
					csvData[j][namefkey] = f;
					csvData[j][nameakey] = a;
				});
			});
			combinedIdx.forEach(({ t, f, a }, k) => {
				// Add the time column value to the csv data if this is the first iteration
				// or if we've somehow got a frame error from the api data and the row is missing
				if (!csvData[k]) {
					csvData[k] = {
						t: moment.utc(t).format(config.dspDateFormat)
					};
				}

				// Generate the f column names
				let namefkey = global
					? `Global Inactive Capacity`
					: `Combined Inactive Capacity`;
				// let nameakey = global
				// 	? `Global Activity Dispersion`
				// 	: `Combined Activity Dispersion`;
				let nameakey = global ? `Global Activity` : `Combined Activity`;

				// Add the column names to the csv headers if this is the first data iteration
				if (k === 0) {
					csvHeaders = [
						...csvHeaders,
						...[
							{
								label: nameakey,
								key: nameakey
							},
							{
								label: namefkey,
								key: namefkey
							}
						]
					];
				}
				// Set the f column on the csv data
				csvData[k][namefkey] = f;
				csvData[k][nameakey] = a;
			});
		}

		return {
			csvFileName,
			csvHeaders,
			csvData
		};
	}

	buildRegionSelectError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select at least one region.
			</Alert>
		);
	}

	buildShortDateRangeError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select a date range of at least 10 days.
			</Alert>
		);
	}

	buildLoadingMessage() {
		return (
			<Fragment>
				<div className="h6 mb-3">Chart data loading...</div>
				<Progress animated value="100" />
			</Fragment>
		);
	}

	// Apply a moving average smoothing to the data provided
	smoothSmelterData({ days, data }) {
		let fSmoothedData = data.map(({ f }) => parseFloat(f));
		fSmoothedData = ma(fSmoothedData, days);

		let aSmoothedData = data.map(({ a }) => parseFloat(a));
		aSmoothedData = ma(aSmoothedData, days);

		return data.map((item, i) => {
			if (i < days - 1) {
				return { ...item, f: null, a: null };
			}
			return {
				...item,
				f: fSmoothedData[i] && fSmoothedData[i].toFixed(1),
				a: aSmoothedData[i] && aSmoothedData[i].toFixed(1)
			};
		});
	}

	buildChartAnnotations() {
		const { steelSmelterUpdates = [] } = this.props;
		const { dateFrom, dateTo } = this.state;
		const totalDays = dateTo.diff(dateFrom, 'days');

		let annotation = {};
		if (steelSmelterUpdates.length > 0) {
			annotation = {
				...annotationsStem,
				annotations: []
			};

			steelSmelterUpdates.forEach(({ detail, update_date }, idx) => {
				let newAnnotation = {
					...annotationStem,
					id: `anno${idx}`,
					value: new Date(update_date),
					label: {
						...annotationStem.label,
						content: detail
					}
				};

				// Position the labels away from the edges
				const pos = Math.floor(
					(moment.utc(update_date).diff(dateFrom, 'days') / totalDays) * 100
				);
				if (pos <= 13) {
					newAnnotation.label.xAdjust = -((detail.length / 2) * 6.1);
				} else if (pos >= 87) {
					newAnnotation.label.xAdjust = (detail.length / 2) * 6.1;
				}

				annotation.annotations.push(newAnnotation);
			});
		}
		return annotation;
	}

	getChartOptionsWithAnnotations(min = null, max = null) {
		// const { showDispersion } = this.state;
		const { showActivity } = this.state;

		//setting y-axis bounds dynamically for dispersion graph
		// const aChartOptionsWithRange =
		// 	min !== null && max !== null
		// 		? {
		// 				...aChartOptions,
		// 				scales: {
		// 					...aChartOptions.scales,
		// 					yAxes: aChartOptions.scales.yAxes.map((ya) => ({
		// 						...ya,
		// 						ticks: { ...ya.ticks, min: min, max: max }
		// 					}))
		// 				}
		// 		  }
		// 		: aChartOptions;

		const fChartOptionsWithRange =
			min === 0 && max === 0
				? {
						...fChartOptions,
						scales: {
							...fChartOptions.scales,
							yAxes: fChartOptions.scales.yAxes.map((ya) => ({
								...ya,
								ticks: { ...ya.ticks, min: 0 }
							}))
						}
				  }
				: fChartOptions;

		// const options = showDispersion
		// 	? aChartOptionsWithRange
		// 	: fChartOptionsWithRange;

		const options = showActivity
			? {
					...fChartOptionsWithRange,
					scales: {
						...fChartOptions.scales,
						yAxes: [
							{
								distribution: 'linear',
								id: 'activity',
								position: 'left',
								scaleLabel: {
									display: true,
									labelString: 'Activity'
								},
								ticks: {
									beginAtZero: false
									// min: 0
								}
							}
						]
					}
			  }
			: fChartOptionsWithRange;

		return {
			...options,
			annotation: this.buildChartAnnotations()
		};
	}

	getSelectedDetail(arr = []) {
		const { global, selected, selectedGrouping, plantTypes, compareCombine } =
			this.state;
		if (arr.length === 1 && global && plantTypes.length !== 1) {
			return [{ key: 'global', ...config.globalRegion }];
		} else if (arr.length === 1 && arr[0] === 'combine') {
			return [{ key: 'combine', ...config.combinedRegion }];
		} else if (
			(plantTypes.length > 1 && compareCombine === 'compare') ||
			(plantTypes.length === 1 &&
				(selectedGrouping[selected]?.length === 1 || global))
		) {
			return arr.map((a) =>
				a === 'bfbof'
					? { key: 'bfbof', title: 'BF/BOF', name: 'BF/BOF', colour: '#36454F' }
					: { key: 'eaf', title: 'EAF', name: 'EAF', colour: '#FF5733' }
			);
		} else if (arr.length >= 1) {
			return arr.map((a) => ({
				key: a.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
				title: a,
				name: a,
				colour:
					selectedGrouping[selected].find(({ name }) => name === a)?.colour ||
					''
			}));
		}
	}

	getChartData(dataArr) {
		const {
			// showDispersion,
			showActivity,
			smoothingDays: days,
			global,
			plantTypes,
			compareCombine
		} = this.state;
		const details = this.getSelectedDetail(dataArr.map(({ name }) => name));

		//area between threshold 50% line and plot should be filled only when showing one dispersion (combine, global or one region) plot
		// const fillPlot = dataArr.length === 1 ? '-1' : false;

		// Build the datasets by iterating the allRegions array and reducing it with the smelterData compare field,
		// so that the original order of the regions is maintained
		return dataArr.map(({ name, data }) => {
			// Smooth the data if required
			if (days !== 0) {
				data = this.smoothSmelterData({
					days,
					data
				});
			}
			const detail = details.find(
				(d) => d.key === name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
			);
			//filled point for combine
			const fCombinePointBackgroundColour =
				name === 'combine' ? { pointBackgroundColor: detail.colour } : {};

			//fStyle will be added to existing active capacity style in configuration
			const fStyle = {
				label: detail.name,
				borderColor: detail.colour,
				...fCombinePointBackgroundColour
			};

			//aStyle will be added to existing activity dispersion style in configuration;
			//threshold will be the first dataset in array with index 0
			// const aStyle =
			// 	name !== 'combine'
			// 		? { pointBackgroundColor: '#FFFFFF', fill: fillPlot }
			// 		: { label: 'Combined index' };
			// let addStyle = {};
			// //will be added to existing activity dispersion style when multiple regions are selected
			// if (
			// 	showDispersion &&
			// 	name !== 'combine' &&
			// 	!global &&
			// 	selected.length > 0
			// ) {
			// 	addStyle = { ...fStyle, ...aStyle };
			// } else {
			// 	addStyle = showDispersion ? aStyle : fStyle;
			// }

			let chartStyle = fStyle;
			if (
				(showActivity &&
					global &&
					plantTypes.length !== 1 &&
					compareCombine !== 'compare') ||
				(showActivity && name === 'combine')
			) {
				chartStyle = {
					...fStyle,
					label: 'Activity',
					borderColor: '#2d9147',
					pointBackgroundColor: '#2d9147'
				};
			} else if (showActivity && name === 'combine') {
				chartStyle = { ...fStyle, label: 'Combined Activity' };
			}

			return {
				data: data.map(({ t, f, a }) => {
					return {
						t: moment.utc(t).format(config.dspDateFormat),
						// y: showDispersion ? a : f
						y: showActivity ? a : f
					};
				}),
				...genChartStyle(this.getChartType()),
				// ...addStyle
				...chartStyle
			};
		});
	}

	getRangeY(dataset) {
		// const { showDispersion } = this.state;
		const data = dataset
			.map((ds) => ds.data.map((d) => d.y))
			.reduce((arr, elem) => arr.concat(elem));
		//smoothing adds some nulls at the beginning of array, remove nulls
		const dataWithoutNull = data.filter((d) => d !== null);
		const maxValue = Math.max(...dataWithoutNull);
		const minValue = Math.min(...dataWithoutNull);
		// if (!showDispersion) {
		return { minY: minValue, maxY: maxValue };
		// }
		// //delta = max(max(maxValue,50)-50, 50-min(50,minValue))
		// const delta = Math.max(
		// 	Math.ceil(Math.max(maxValue, 50)) - 50,
		// 	50 - Math.floor(Math.min(50, minValue))
		// );
		// return { minY: 50 - delta, maxY: 50 + delta };
	}

	// Individual data lines shown for multiple regions
	buildLineChart() {
		const { steelSmelterData } = this.props;

		const {
			compareCombine,
			global,
			selected,
			selectedGrouping,
			// showDispersion
			plantTypes,
			dataByPlant
		} = this.state;
		const isCombined =
			compareCombine === 'combine' &&
			!global &&
			selectedGrouping[selected]?.length > 1;

		const isCombinedPlants =
			(global || selectedGrouping[selected]?.length === 1) &&
			plantTypes.length !== 1 &&
			compareCombine !== 'compare';

		let apiData = '';

		if (isCombined) {
			apiData = [
				{
					name: 'combine',
					data:
						steelSmelterData.combinedIdx ||
						(steelSmelterData?.singleIdx &&
							steelSmelterData.singleIdx[0]?.data) ||
						''
				}
			];
		} else if (isCombinedPlants && global) {
			apiData = [
				{
					name: 'Global',
					data: dataByPlant.combined
				}
			];
		} else if (isCombinedPlants && selectedGrouping[selected]?.length === 1) {
			apiData = [
				{
					name: selectedGrouping[selected][0].name,
					data: dataByPlant.combined
				}
			];
		} else if (
			!isCombinedPlants &&
			(global || selectedGrouping[selected]?.length === 1)
		) {
			apiData = plantTypes.map((name) => ({ name, data: dataByPlant[name] }));
		} else {
			apiData =
				steelSmelterData.singleIdx ||
				selectedGrouping[selected]?.map(({ name }) => ({ name, data: '' }));
		}

		//compare data length gives info whether API response is received, combined data length doesn't contain that information
		if (!apiData || apiData.every(({ data }) => data === '')) {
			return this.buildLoadingMessage();
		}

		//check this condition
		if (apiData.every(({ data }) => data.length === 0)) {
			return;
		}
		const datasets = this.getChartData(apiData);

		const { minY, maxY } = this.getRangeY(datasets);

		//build data for horizontal line at 50% of activity dispersion plot
		// if (showDispersion) {
		// 	const threshold = {
		// 		data: apiData[0].data.map((point) => ({
		// 			t: moment.utc(point.t).format(config.dspDateFormat),
		// 			y: 50
		// 		})),
		// 		...aThresholdStyle
		// 	};
		// 	datasets.unshift(threshold);
		// }
		return (
			<Card className="mb-3">
				{this.buildCardHeader()}
				<CardBody>
					<Container
						fluid
						className="pl-0 container-width"
						style={{ paddingRight: '21px' }}
					>
						<Row noGutters className="mt-0">
							<Col xs="12" sm="12">
								<Line
									data={{ datasets }}
									options={this.getChartOptionsWithAnnotations(minY, maxY)}
									redraw
								/>
							</Col>
						</Row>
					</Container>
				</CardBody>
			</Card>
		);
	}

	render() {
		let { global, shortDateRange, selectedGrouping, selected } = this.state;
		return (
			<DocumentTitle title="GAINS | Steel Smelter Operations">
				<div className="content-wrapper">
					<Container fluid>
						{/* Help, crumbs and the filter form */}
						{/* <div className="pull-right">{smeltersInlineHelpContent}</div> */}
						<Crumbs type="steel" path={[{ title: 'Smelter Indices' }]} />
						{this.buildFilterForm()}

						{/* Error messages */}
						{shortDateRange && this.buildShortDateRangeError()}
						{!shortDateRange &&
							!global &&
							!selectedGrouping[selected]?.length &&
							this.buildRegionSelectError()}

						{/* Different chart types */}
						{!shortDateRange &&
							(selectedGrouping[selected]?.length > 0 || global) &&
							this.buildLineChart()}
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({
	lastUploadDate,
	locations,
	groups,
	steelSmelterData,
	steelSmelterUpdates
}) => {
	const { regions } = locations;
	const filteredGroups =
		regions.length > 1
			? groups
			: Object.keys(groups)
					.filter((objKey) => objKey !== 'Custom region')
					.reduce((newObj, key) => {
						newObj[key] = groups[key];
						return newObj;
					}, {});
	return {
		lastUploadDate,
		locations,
		groups: filteredGroups,
		steelSmelterData: steelSmelterData.data,
		steelSmelterUpdates
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchLastUploadDate: (filterData) =>
		dispatch(fetchLastUploadDate(filterData)),
	resetLastUploadDate: () => dispatch(resetLastUploadDate()),
	fetchSteelSmelterData: (filterData) =>
		dispatch(fetchSteelSmelterData(filterData)),
	resetSteelSmelterData: () => dispatch(resetSteelSmelterData()),

	fetchLocations: (filterData) => dispatch(fetchLocations(filterData)),
	resetLocations: () => dispatch(resetLocations()),

	fetchGroups: (filterData) => dispatch(fetchGroups(filterData)),
	resetGroups: () => dispatch(resetGroups())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SteelSmelters));
