import { API } from 'aws-amplify';

import {
	FETCH_SCRAP_STEEL_DATA,
	FETCH_SCRAP_STEEL_DATA_ERR,
	FETCH_SCRAP_STEEL_DATA_BY_SITE,
	FETCH_SCRAP_STEEL_DATA_BY_SITE_ERR
} from '.';
import config from '../config';
import { replacer } from '../utils';

export const fetchScrapSteelData =
	({ grouping, ids, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.scrapSteelData, {
				grouping,
				ids,
				startDate,
				endDate
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_SCRAP_STEEL_DATA,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_SCRAP_STEEL_DATA_ERR,
					payload: err
				})
		);
	};

export const fetchScrapSteelDataInitial = async ({
	grouping,
	ids,
	startDate,
	endDate
}) => {
	return await API.get(
		'gains-api',
		replacer(config.apiEndpoints.scrapSteelData, {
			grouping,
			ids,
			startDate,
			endDate
		}),
		{
			headers: {}
		}
	);
};

export const resetScrapSteelData = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_SCRAP_STEEL_DATA, payload: [] });
	};
};

export const fetchScrapSteelDataBySite =
	({ ids, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.scrapSteelData, {
				ids,
				startDate,
				endDate
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_SCRAP_STEEL_DATA_BY_SITE,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_SCRAP_STEEL_DATA_BY_SITE_ERR,
					payload: err
				})
		);
	};

export const resetScrapSteelDataBySite = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_SCRAP_STEEL_DATA_BY_SITE, payload: {} });
	};
};
