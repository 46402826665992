import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Auth } from 'aws-amplify';
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row,
	UncontrolledTooltip
} from 'reactstrap';

import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga4';
import { arrayToTree } from 'performant-array-to-tree';

import {
	fetchLocations,
	resetLocations,
	fetchGroups,
	resetGroups,
	// fetchSites,
	// resetSites,
	fetchLastUploadDate,
	resetLastUploadDate,
	fetchSteelSmelterHotspotsAllTime,
	resetSteelSmelterHotspotsAllTime,
	fetchSteelSmeltersState,
	resetSteelSmeltersState,
	shrinkSmelterAllTimeMapData,
	resetShrinkedSmelterAllTimeMapData,
	selectSteelSmelter,
	unselectSteelSmelter
} from 'actions';
import { DateSkipper, SiteMap } from 'components';

import { Crumbs, StripPlotContainer } from 'containers';
// import { isUserSales, isUserEnterprise } from '../../../utils';
import MultiSelect from 'components/multiselect/MultiSelect';
import './SmeltersMap.scss';

// import staticMap from 'images/world-steel-smelter-map.png';
// import 'steel-map-all.png' from 'images/*';

const defaultRegion = 'all';
const defaultTitle = 'All';
const apiDateFormat = 'YYYY-MM-DD';
const apiDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
const csvDateFormat = 'YYYY-MM-DD';

class SteelSmeltersMap extends Component {
	state = {
		dateMin: moment.utc(),
		dateMax: moment.utc(),
		selectedDate: moment.utc(),
		groupType: 'region',
		currentRegion: '',
		currentCountries: [],
		currentPlantType: '',
		visiblePlantTypes: [],
		highlightId: '',
		visibleSiteIds: [],
		expandedSites: [],
		canDownload: false,
		storedSteelSelectedSmelters: false,
		showAllData: false
	};

	constructor(props) {
		super(props);
		this.entityRefs = {};
		this.plotsRef = React.createRef();
	}

	async componentDidMount() {
		const authUser = await Auth.currentAuthenticatedUser();
		this.userSub = authUser.attributes['sub'] || '';

		const { steelSelectedSmelters } = this.props;
		if (steelSelectedSmelters.length) {
			this.setState({ storedSteelSelectedSmelters: true });
		}

		this.props.fetchLocations({ commodity: 'steel' });
		this.props.fetchGroups({ commodity: 'steel' });
		this.props.fetchLastUploadDate({ commodity: 'steel' });
	}

	componentWillUnmount() {
		this.props.resetShrinkedSmelterAllTimeMapData();
		this.props.resetSteelSmelterHotspotsAllTime();
		this.props.resetSteelSmeltersState();
		// this.props.resetMySmelters();
		// this.props.resetSites();
		this.props.resetGroups();
		this.props.resetLocations();
		this.props.resetLastUploadDate();
	}

	// Updates from region navigation trigger a data request
	async componentDidUpdate(
		{
			match: {
				params: { regionId: prevRegionId }
			},
			lastUploadDate: prevLastUploadDate,
			steelSelectedSmelters: prevSteelSelectedSmelters,
			sites: prevSites,
			steelSmeltersState: prevSteelSmeltersState,
			countries: prevPropsCountries
		},
		{
			selectedDate: prevSelectedDate,
			currentRegion: prevCurrentRegion,
			currentCountries: prevCountries,
			currentPlantType: prevPlantType,
			groupType: prevGroupType
		}
	) {
		const {
			regionKey,
			lastUploadDate,
			steelSelectedSmelters,
			regions,
			countries,
			sites,
			steelSmeltersState
		} = this.props;
		const {
			selectedDate,
			groupType,
			currentRegion,
			currentCountries,
			currentPlantType,
			storedSteelSelectedSmelters
		} = this.state;

		//Date setting
		if (lastUploadDate !== prevLastUploadDate) {
			await this.updateDateHandling({ lastUploadDate });
		}

		if (!lastUploadDate) {
			return;
		}
		if (
			currentCountries.length === 0 &&
			countries.length > prevPropsCountries &&
			regions.length < 3
		) {
			this.setState({ currentCountries: countries });
			return;
		}
		//handling regions
		// if (!currentRegion && groupType === 'region' && regionKey === 'countries') {
		// 	//handling link in browser set to 'countries'
		// 	this.setState({ groupType: 'country' });
		// 	return;
		// }
		if (
			(!currentRegion || groupType !== prevGroupType) &&
			(groupType === 'region' || groupType === 'plant')
		) {
			if (!regions?.length) return;
			//if no regionKey or regionKey is unknown, set default regionKey
			const region =
				!regionKey ||
				!regions.map(({ key }) => key).includes(regionKey) ||
				groupType === 'plant'
					? defaultRegion
					: regionKey;

			// const selectedRegion = smelterRegions.find(({ key }) => key === region);
			if (regions.length > 2) {
				this.setState({
					currentRegion: region, //key
					currentCountries: []
				});
				this.props.history.push(`/steel/smeltersMap/${region}`);
			} else {
				this.setState({
					currentRegion: 'countries' //key
				});
				this.props.history.push(`/steel/smeltersMap/countries`);
			}
			return;
		} else if (
			// !currentCountries.length &&
			groupType === 'country' &&
			groupType !== prevGroupType
		) {
			if (!countries?.length) return;
			this.setState({
				currentRegion: 'countries' //key
			});
			// this.props.history.push(`/steel/smeltersMap/countries`);
			return;
		}

		// handling regions: by clicking Smelter Map in the menu, you stay in the same view
		if (!regionKey && currentRegion) {
			this.props.history.push(`/steel/smeltersMap/${currentRegion}`);
			return;
		}

		//handling regions
		if (
			currentRegion !== prevCurrentRegion ||
			currentCountries.length !== prevCountries.length ||
			currentPlantType !== prevPlantType
		) {
			if (prevCurrentRegion) {
				//only on region switching or toggling Index/Non-Index Smelters
				this.props.history.push(`/steel/smeltersMap/${currentRegion}`);
			}

			this.getMapData();
			return;
		}
		if (
			steelSmeltersState.length > prevSteelSmeltersState.length &&
			!prevSteelSmeltersState.length &&
			currentPlantType === ''
		) {
			const filteredSites = sites.filter(({ id }) =>
				steelSmeltersState.map(({ id }) => id).includes(id)
			);

			this.setState({
				visiblePlantTypes: [
					...new Set(filteredSites.map(({ newPlantType }) => newPlantType))
				].sort((r1, r2) => (r1 > r2 ? 1 : -1))
			});
		}

		//region has to be set up to this point
		if (!prevSelectedDate.isSame(selectedDate, 'day') && currentRegion) {
			this.getMapData();
			return;
		}

		//only place to fetch data for selected smelters
		if (
			(steelSelectedSmelters.length &&
				steelSelectedSmelters.length !== prevSteelSelectedSmelters.length) ||
			storedSteelSelectedSmelters
		) {
			//since component is already mounted, we're not interested anymore in stored selected smelters
			this.setState({ storedSteelSelectedSmelters: false });
			await this.props.fetchSteelSmelterHotspotsAllTime({
				smelterIds: steelSelectedSmelters
			});
			const { steelSmelterAllTimeBySmelter: smelterData } = this.props;
			this.props.shrinkSmelterAllTimeMapData({ smelterData });
			return;
		} else if (
			!steelSelectedSmelters.length &&
			steelSelectedSmelters.length < prevSteelSelectedSmelters.length
		) {
			//unselect all smelters
			this.props.resetShrinkedSmelterAllTimeMapData();
			this.props.resetSteelSmelterHotspotsAllTime();
		}
	}

	async updateDateHandling({ lastUploadDate }) {
		// const { authenticatedUserGroups: authGroups } =
		// 	await getAuthenticatedUserGroups();
		const maxDate = moment.utc(lastUploadDate);
		//only 'sales' and  'savant-enterprise' are currently in use
		//for enterprise user and part of state for 'sales'
		let dateState = {
			selectedDate: maxDate,
			dateMin: moment.utc('20160301'),
			dateMax: maxDate
		};
		let downloadState = {};

		this.setState({
			...dateState,
			...downloadState
		});
	}

	async getMapData() {
		const { regions, fetchSteelSmeltersState } = this.props;
		const { selectedDate, currentRegion, currentCountries } = this.state;

		const regionId = regions.find(({ key }) => key === currentRegion)?.id;
		await fetchSteelSmeltersState({
			regionIds: regionId ? [regionId] : '',
			countryIds: currentCountries.map(({ id }) => id),
			// plantType: currentPlantType,
			plantType: '',
			searchDate: selectedDate.format(apiDateFormat)
		});
	}

	onInputSelect = async ({ target: { value, name } }) => {
		if (name === 'groupType') {
			if (value === 'region' || value === 'country') {
				this.setState({
					currentPlantType: '',
					currentCountries: [],
					currentRegion: ''
				});
				this.props.resetSteelSmeltersState();
			}
			this.setState({
				groupType: value,
				currentPlantType: ''
			});
		}
	};
	// Handle the selection of region
	onRegionBtnClick({ regionKey }) {
		this.setState({ currentRegion: regionKey, currentPlantType: '' });
		this.props.resetSteelSmeltersState();
	}

	// Handle the selection of plant type
	onPlantTypeBtnClick({ plantKey }) {
		const { currentPlantType } = this.state;

		this.setState({
			currentPlantType: currentPlantType === plantKey ? '' : plantKey
		});
		// this.props.resetSteelSmeltersState();
	}

	handleMultipleSelections = (vals) => {
		this.setState({
			currentCountries: vals,
			currentPlantType: ''
		});
		this.props.resetSteelSmeltersState();
	};

	// Handle date selection event
	async onDateSelected({ selectedDate }) {
		const dspSelectedDate = selectedDate.format(apiDateFormat);
		await this.setState({
			selectedDate: moment.utc(dspSelectedDate)
		});
	}

	async onStartPlayBackward() {
		await this.setState({
			playBackward: true,
			playForward: false
		});
	}

	async onStopPlay() {
		await this.setState({
			playBackward: false,
			playForward: false
		});
	}

	async onStartPlayForward() {
		await this.setState({
			playBackward: false,
			playForward: true
		});
	}

	// Function to swap the map cursor when over a smelter
	onMapEntHov(enter) {
		if (!this.viewer) {
			this.viewer = document.getElementById('viewer');
		}
		this.viewer.style.cursor = !!enter ? 'pointer' : 'default';
	}

	// Checkbox click for the smelter checkboxes
	onCheckboxBtnClick({ prop, val }) {
		const { steelSelectedSmelters } = this.props;
		if (steelSelectedSmelters.includes(val)) {
			this.props.unselectSteelSmelter(val);
		} else {
			this.props.selectSteelSmelter(val);
		}
	}

	toggleDateRange() {
		this.setState({ showAllData: !this.state.showAllData });
	}

	buildRegionButtons() {
		const { regions, regionKey } = this.props;
		return (
			<ButtonGroup className="mb-2 ml-3">
				{regions.map(({ key, name }) => {
					return (
						<Fragment key={`region${name}`}>
							<Button
								id={`region_${key}`}
								key={`button_${key}`}
								outline
								size="sm"
								color="secondary"
								onClick={() => this.onRegionBtnClick({ regionKey: key })}
								active={regionKey === key}
								disabled={false}
								aria-label={name}
							>
								{key === defaultRegion ? defaultTitle : name}
							</Button>
						</Fragment>
					);
				})}
			</ButtonGroup>
		);
	}

	buildPlantTypeButtons() {
		const { visiblePlantTypes } = this.state;

		return (
			<ButtonGroup className="mb-2 ml-3">
				{visiblePlantTypes.map((name) => {
					return (
						<Fragment key={`plant${name}`}>
							<Button
								id={`plant_${name}`}
								key={`button_${name}`}
								outline
								size="sm"
								color="secondary"
								onClick={() => this.onPlantTypeBtnClick({ plantKey: name })}
								active={this.state.currentPlantType === name}
								disabled={false}
								aria-label={name}
							>
								{name}
							</Button>
						</Fragment>
					);
				})}
			</ButtonGroup>
		);
	}

	// Build csv data from the props for the download feature
	getCsvDataFromProps() {
		const { steelSmeltersState = [], regionKey } = this.props;
		const { selectedDate } = this.state;

		// Initialise our csv data
		let csvFileName = `smelter-map-data-${regionKey}-${selectedDate.format(
			apiDateFormat
		)}.csv`;
		let csvHeaders = [
			{ label: 'Name', key: 'name' },
			{ label: 'Capacity', key: 'capacity' },
			{ label: 'Capture time', key: 'capture_date' },
			{ label: 'State', key: 'state' }
		];
		let csvData = steelSmeltersState.map(
			({ name, capacity, capture_date, state }) => {
				let dspState = 'unknown';
				if (state === 1) {
					dspState = 'on';
				} else if (state === 0) {
					dspState = 'off';
				}

				return {
					name,
					capacity,
					capture_date: moment.utc(capture_date).format(csvDateFormat),
					state: dspState
				};
			}
		);

		return {
			csvFileName,
			csvHeaders,
			csvData
		};
	}

	buildCsvDownloadButton() {
		const { canDownload } = this.state;
		const { csvHeaders, csvData, csvFileName } = this.getCsvDataFromProps();
		if (csvData.length !== 0 && canDownload) {
			return (
				<CSVLink
					id="buttonDownload"
					filename={csvFileName}
					headers={csvHeaders}
					data={csvData}
					className="btn btn-success mb-3 pull-right btn-sm"
					onClick={() => {
						ReactGA.event({
							category: 'steel_sites_state_download',
							action: 'download',
							label: this.userSub
						});

						return true;
					}}
				>
					<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
					data
				</CSVLink>
			);
		}

		return (
			<Button
				id="buttonDownload"
				color="success"
				disabled
				className="mb-3 pull-right"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
				data
			</Button>
		);
	}

	// Build the filter form
	buildFilterForm() {
		const { groupType, currentCountries, selectedDate, dateMin, dateMax } =
			this.state;
		const { regions, countries, lastUploadDate } = this.props;

		return (
			<Form>
				<Container fluid className="px-0">
					<Row noGutters>
						<Col xs="12" sm="12">
							<Label for="groupType" className="mb-2 mr-3 align-top">
								<span className="sr-only">Geographical filtering type</span>
								<Input
									id="groupType"
									name="groupType"
									type="select"
									bsSize="sm"
									className="pointered"
									value={groupType}
									onChange={this.onInputSelect}
								>
									{regions.length !== 2 && (
										<option value="region">Region filtering</option>
									)}
									<option value="country">Country filtering</option>
								</Input>
							</Label>

							{groupType === 'region' &&
								regions.length > 2 &&
								this.buildRegionButtons()}

							{(groupType === 'country' || regions.length === 2) && (
								<MultiSelect
									options={countries}
									values={currentCountries}
									limit={5}
									onMultipleSelection={this.handleMultipleSelections}
								/>
							)}

							{(groupType === 'region' || groupType === 'country') &&
								this.buildPlantTypeButtons()}
						</Col>
					</Row>
					<Row noGutters>
						<Col xs="12" sm="12">
							<DateSkipper
								id="ds1"
								className="mr-3 mb-3 align-middle"
								weekAdv={true}
								monthAdv={true}
								selectedDate={selectedDate}
								maxDate={dateMax}
								minDate={dateMin}
								newestDate={lastUploadDate}
								onDateSelected={this.onDateSelected.bind(this)}
							/>
							{this.state.canDownload && this.buildCsvDownloadButton()}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	//days since last valid reading
	getElapsedDays(captureDate) {
		const { selectedDate } = this.state;
		const days = selectedDate.diff(captureDate, 'days');
		return days > 0 ? `${days}d` : '0d';
	}

	toggleCollapse = (id, children) => {
		const { expandedSites } = this.state;
		const index = expandedSites.indexOf(id);
		if (index < 0) {
			this.setState({ expandedSites: [...expandedSites, id] });
		} else {
			const chIds = children.map(({ id }) => id);
			chIds.forEach((id) => {
				const index = expandedSites.indexOf(id);
				if (index > 0) {
					expandedSites.splice(index, 1);
				}
			});
			expandedSites.splice(index, 1);
			this.setState({ expandedSites });
		}
	};

	renderSiteRow = ({
		id,
		name,
		state,
		captured,
		children,
		parent_id,
		pState,
		eDate
	}) => {
		const { steelSelectedSmelters } = this.props;
		const { selectedDate, visibleSiteIds, expandedSites } = this.state;
		const smelterIds = visibleSiteIds.map((id) => id);

		const iconClass = `circle ${
			eDate && selectedDate.isSameOrAfter(moment.utc(eDate), 'day')
				? 'shutdown'
				: state === 1
				? 'active'
				: 'inactive'
		}`;
		const iconSr =
			eDate && selectedDate.isSameOrAfter(moment.utc(eDate), 'day')
				? 'Shutdown'
				: state === 1
				? 'Active'
				: 'Inactive';
		return (
			<Fragment key={id}>
				<div className="flex-row-cells" role="rowgroup">
					<div className="flex-cell" role="cell">
						{smelterIds.includes(id) && !steelSelectedSmelters.includes(id) && (
							<i
								className={
									steelSelectedSmelters.length < 10
										? 'fa fa-square-o pointered'
										: 'fa fa-square-o pointered disabled'
								}
								aria-hidden="true"
								onClick={() =>
									this.onCheckboxBtnClick({
										prop: 'steelSelectedSmelters',
										val: id
									})
								}
							/>
						)}
						{smelterIds.includes(id) && steelSelectedSmelters.includes(id) && (
							<i
								className="fa fa-check-square-o pointered"
								aria-hidden="true"
								onClick={() =>
									this.onCheckboxBtnClick({
										prop: 'steelSelectedSmelters',
										val: id
									})
								}
							/>
						)}
					</div>
					<div
						id={`smelter-${id}`}
						className="flex-cell pr-1"
						role="cell"
						onMouseEnter={() => this.setState({ highlightId: id })}
						onMouseLeave={() => this.setState({ highlightId: '' })}
					>
						{pState ? (
							<Fragment>
								<span
									className={`title-base ${parent_id ? 'title-small' : ''}`}
								>
									{name}
								</span>
								<span className="asterisk">*</span>
							</Fragment>
						) : (
							<span className={`title-base ${parent_id ? 'title-small' : ''}`}>
								{name}
							</span>
						)}
						<a
							href={`#subsites${id}`}
							className=""
							role="rowgroup"
							data-toggle="collapse"
							onClick={() => {
								this.toggleCollapse(id, children);
							}}
						>
							{children?.length > 0 && !expandedSites.includes(id) && (
								<span>
									<i className="fa fa-plus-square pl-2" aria-hidden="true"></i>
								</span>
							)}
							{children?.length > 0 && expandedSites.includes(id) && (
								<span>
									<i className="fa fa-minus-square pl-2" aria-hidden="true"></i>
								</span>
							)}
						</a>
					</div>
					<UncontrolledTooltip
						placement="top"
						delay={{ show: 1000, hide: 500 }}
						target={`smelter-${id}`}
					>
						{name}
					</UncontrolledTooltip>
					<div className="flex-cell" role="cell">
						<div className={`icon ${iconClass}`}>
							<span className="sr-only">{iconSr} </span>
						</div>
						<div className="e-days">{this.getElapsedDays(captured)}</div>
					</div>
				</div>
				<div
					id={`subsites${id}`}
					className={`collapse ${
						expandedSites.includes(id) ? 'show' : ''
					} pl-4`}
				>
					{children?.map(
						({
							id,
							name,
							state,
							captured,
							children,
							parent_id,
							prod_state: pState,
							end_date: eDate
						}) => {
							return this.renderSiteRow({
								id,
								name,
								state,
								captured,
								children,
								parent_id,
								pState,
								eDate
							});
						}
					)}
				</div>
			</Fragment>
		);
	};

	buildSmelterList() {
		const { steelSmeltersState, sites } = this.props;
		const { currentPlantType, visibleSiteIds } = this.state;

		const visibleSites =
			sites?.filter(({ id }) => visibleSiteIds?.includes(id)) || [];
		const isSmelterMapData =
			steelSmeltersState && steelSmeltersState.length > 0;
		const displayNonIndexNote =
			visibleSites.filter(({ prod_state }) => prod_state === 1).length > 0;

		const expVisibleSites = visibleSites.map((vs) => ({
			...vs,
			...steelSmeltersState.find((s) => s.id === vs.id)
		}));

		const roots =
			currentPlantType === 'Not Assigned'
				? expVisibleSites
				: arrayToTree(expVisibleSites, {
						dataField: null,
						parentId: 'parent_id'
				  });

		return (
			<div className="smelter-list pb-1" role="table">
				<div className="flex-row " role="rowheader">
					<div className="flex-col py-1" role="columnheader">
						<i className="fa fa-bar-chart" aria-hidden="true" />
					</div>
					<div className="flex-col pb-1" role="columnheader">
						Smelter
					</div>
					<div id="status-header" className="flex-col" role="columnheader">
						Status
					</div>
					<UncontrolledTooltip
						placement="top"
						delay={{ show: 1000, hide: 500 }}
						target="status-header"
					>
						Current status and the days since last reading
					</UncontrolledTooltip>
				</div>
				<div className="tb-wrapper">
					<div className="table-body" role="list">
						{!visibleSites.length ? (
							<div className="no-monitor">
								No info about monitored smelters in this region
							</div>
						) : !isSmelterMapData ? (
							<div className="no-monitor">Still waiting for data</div>
						) : (
							roots.map(
								({
									id,
									name,
									state,
									captured,
									children,
									parent_id,
									prod_state: pState,
									end_date: eDate
								}) =>
									this.renderSiteRow({
										id,
										name,
										state,
										captured,
										children,
										parent_id,
										pState,
										eDate
									})
							)
						)}
					</div>
					{isSmelterMapData && (
						<div className="table-notes text-muted small mx-n3 p-1">
							<div className="mb-0">
								<p className="mb-0">
									<strong>Status</strong> indicates current state and days (d)
									since last reading.
								</p>
								{displayNonIndexNote ? (
									<p className="mt-0">
										<span className="asterisk">*</span> Not included in the
										Index as either not yet operational or the data is not
										consistent enough.
									</p>
								) : null}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}

	getPointRef(id) {
		// Create a ref for the PointGraphics and add it to the entityRefs collection if it is not already created
		if (!this.entityRefs.hasOwnProperty(id)) {
			this.entityRefs[id] = React.createRef();
		}
		return this.entityRefs[id];
	}

	handleVisibleSites = (ids) => {
		this.setState({ visibleSiteIds: ids });
	};

	buildMap() {
		const { sites, steelSmeltersState } = this.props;
		const { currentPlantType } = this.state;

		if (
			(sites.length === 0 && selectSteelSmelter.length !== 0) ||
			(sites.length !== 0 && selectSteelSmelter.length === 0)
		)
			return null;

		const sitesByPlant =
			currentPlantType === ''
				? sites
				: sites.filter(({ newPlantType }) => newPlantType === currentPlantType);

		const mapData = sitesByPlant
			.filter((site) => steelSmeltersState.some(({ id }) => id === site.id))
			.map((site) => ({
				...site,
				...steelSmeltersState.find(({ id }) => id === site.id),
				highlight: this.state.highlightId === site.id
			}))
			.reverse();
		return (
			<SiteMap
				sites={mapData}
				onVisibleSitesUpdated={(siteIds) => this.handleVisibleSites(siteIds)}
			/>
		);
	}

	buildLegend() {
		return (
			<Fragment>
				<div className="circle active align-text-bottom mr-2" /> Active
				<div className="circle inactive align-text-bottom mr-2 ml-4" /> Inactive
				<div className="circle shutdown align-text-bottom mr-2 ml-4" /> Shutdown
			</Fragment>
		);
	}

	getAlignedSmelterdData(capacityData, activityData) {
		const { showAllData, dateMax } = this.state;

		const cData = showAllData
			? capacityData
			: capacityData.filter(({ t }) => dateMax.diff(t, 'years', true) <= 2);

		const aData = showAllData
			? activityData
			: activityData.filter(({ t }) => dateMax.diff(t, 'years', true) <= 2);
		if (!cData.length || !aData.length) {
			return { alignedCapacityData: [], alignedActivityData: [] };
		}
		if (!showAllData) {
			//handling capacity data cut off
			const prevCData = capacityData.filter(
				({ t }) => dateMax.diff(t, 'years', true) > 2
			);
			const prevValidItem = prevCData.length ? prevCData.pop() : null;
			const firstTimestamp = dateMax
				.clone()
				.subtract(2, 'years')
				.format(apiDateTimeFormat);
			if (
				moment.utc(cData[0].t).diff(firstTimestamp, 'days') !== 0 &&
				prevValidItem
			) {
				cData.unshift({ t: firstTimestamp, v: prevValidItem.v });
			}
		}

		let alignedData = [];

		if (moment.utc(aData[0].t).isBefore(cData[0].t, 'day')) {
			alignedData = aData.filter(
				(ad) =>
					moment.utc(ad.t).isSame(cData[0].t, 'day') ||
					moment.utc(ad.t).isAfter(cData[0].t, 'day')
			);
			return { alignedCapacityData: cData, alignedActivityData: alignedData };
		} else if (moment.utc(cData[0].t).isBefore(aData[0].t, 'day')) {
			alignedData = cData.filter(
				(cd) =>
					moment.utc(cd.t).isSame(aData[0].t, 'day') ||
					moment.utc(cd.t).isAfter(aData[0].t, 'day')
			);
			if (moment.utc(aData[0].t).isBefore(alignedData[0].t, 'day')) {
				//find the last value for capacity which is cut off
				const indexOrig = cData.findLastIndex((cd) =>
					moment.utc(cd.t).isBefore(alignedData[0].t)
				);
				//add missing item
				if (indexOrig >= 0) {
					alignedData = [
						{ t: aData[0].t, v: cData[indexOrig].v },
						...alignedData
					];
				}
			}
			return { alignedCapacityData: alignedData, alignedActivityData: aData };
		}
		return { alignedCapacityData: cData, alignedActivityData: aData };
	}

	buildStripPlots() {
		const {
			sites,
			steelSelectedSmelters: selectedIds,
			steelSmelterAllTimeBySmelter: {
				capacity = {},
				activity = {},
				weeklyProd = {},
				monthlyProd = {},
				yearlyProd = {}
			} = {},
			smelterShrinkedAllTimeData,
			steelNewestDate
		} = this.props;
		const { showAllData, dateMax } = this.state;

		const selectedSmelters = sites.filter(({ id }) =>
			selectedIds.some((sId) => sId === id)
		);

		if (selectedSmelters.length === 0) {
			return null;
		}

		//show strip plots in the order they're selected, the most recent selected at top
		const sortedSelectedSmelters = [];
		selectedIds.forEach((sId) => {
			const idx = selectedSmelters.findIndex(({ id }) => sId === id);
			if (idx > -1) {
				sortedSelectedSmelters.unshift(selectedSmelters[idx]);
			}
		});

		return (
			<Fragment>
				<div
					className="d-flex justify-content-between mt-3 mb-2"
					ref={this.plotsRef}
				>
					<h5 className="mb-0">
						Detailed Smelter activity -{' '}
						<span className="h6 font-weight-normal text-muted">
							Activity and Production plots per chosen Smelter
						</span>
					</h5>
					<ButtonGroup className="ml-2 flex-shrink-0 align-self-start">
						<Button
							outline
							size="sm"
							color="secondary"
							onClick={() => this.toggleDateRange()}
							active={!showAllData}
							disabled={false}
							aria-label="Click to display indices for the last two years"
						>
							Last two years
						</Button>
						<Button
							outline
							size="sm"
							color="secondary"
							onClick={() => this.toggleDateRange()}
							active={showAllData}
							disabled={false}
							aria-label="Click to display indices since the beginning of observations"
						>
							All Dates
						</Button>
					</ButtonGroup>
				</div>
				{sortedSelectedSmelters.map(
					(
						{
							name,
							id: smelterId,
							prod_state: pState,
							start_date: sDate,
							end_date: eDate
						},
						idx
					) => {
						const title = name;
						const type = 'steel';
						const capacityData = capacity[name] ? capacity[name].data : [];
						const shrinkedCapacityData = smelterShrinkedAllTimeData[name]
							? smelterShrinkedAllTimeData[name]
							: [];
						const activityData = activity[name] ? activity[name].data : [];
						const weeklyProdData = weeklyProd[name]
							? showAllData
								? weeklyProd[name].data
								: weeklyProd[name].data.filter(
										({ t }) =>
											dateMax.diff(
												moment.utc(t, 'W YYYY').format('YYYY-MM-DD'),
												'years',
												true
											) <= 2
								  )
							: [];

						const monthlyProdData = monthlyProd[name]
							? showAllData
								? monthlyProd[name].data
								: monthlyProd[name].data.filter(
										({ t }) =>
											dateMax.diff(
												moment.utc(t, 'MMM YYYY').format('YYYY-MM-DD'),
												'years',
												true
											) <= 2
								  )
							: [];
						const yearlyProdData = yearlyProd[name]
							? showAllData
								? yearlyProd[name].data
								: yearlyProd[name].data.filter(
										({ t }) =>
											dateMax.diff(
												moment.utc(t, 'YYYY').format('YYYY-MM-DD'),
												'years',
												true
											) <= 2
								  )
							: [];
						//waiting for data
						if (!activityData.length || !shrinkedCapacityData.length) {
							return null;
						}

						const { alignedCapacityData, alignedActivityData } =
							this.getAlignedSmelterdData(shrinkedCapacityData, activityData);

						// first day in index
						const startDate = pState
							? ''
							: sDate && moment.utc(sDate).isAfter('20160301', 'day')
							? moment.utc(sDate).format(apiDateFormat)
							: moment.utc('20160301').format(apiDateFormat);

						//last day in index
						const endDate = pState
							? ''
							: eDate
							? moment.utc(eDate).format(apiDateFormat)
							: '';

						const plotProps = {
							activityData,
							alignedActivityData,
							capacityData,
							alignedCapacityData,
							weeklyProdData,
							allWeeklyProdData: weeklyProd[name].data,
							monthlyProdData,
							allMonthlyProdData: monthlyProd[name].data,
							yearlyProdData,
							allYearlyProdData: yearlyProd[name].data,
							csvDateFormat,
							idx,
							maxDate: dateMax,
							newestDate: steelNewestDate,
							startDate,
							endDate,
							title,
							pState,
							type,
							smelterId,
							noDataWarning:
								!alignedCapacityData.length || !alignedActivityData.length
						};

						return (
							<StripPlotContainer
								{...plotProps}
								key={`spContainer${smelterId}`}
							/>
						);
					}
				)}
			</Fragment>
		);
	}

	renderSmelterMapPicture() {
		const { currentRegion } = this.state;
		const regionAlias = currentRegion === 'countries' ? 'all' : currentRegion;
		return (
			<Card className="mb-3 smelter-map-dashlet h-100 rounded-0">
				<CardBody className="p-2">
					<img
						src={`${process.env.PUBLIC_URL}/images/steel-map-${regionAlias}.png`}
						className="card-img-top img-fluid"
						alt="Smelter locations"
					/>
				</CardBody>
			</Card>
		);
	}

	render() {
		return (
			<DocumentTitle title="GAINS | Smelter Map">
				<div className="content-wrapper">
					<Container fluid>
						<Crumbs type="steel" path={[{ title: 'Smelter Map' }]} />
						{this.buildFilterForm()}
						<Row noGutters className="map-container">
							<Col xs="4" className="p-3 map-left-panel">
								{this.buildSmelterList()}
							</Col>
							{/* <Col xs="9">{this.renderSmelterMapPicture()}</Col> */}
							<Col xs="8">{this.buildMap()}</Col>
						</Row>
						<Row noGutters>
							<Col xs="12" className="map-legend py-2 text-center small mb-3">
								{this.buildLegend()}
							</Col>
						</Row>
						<Row noGutters>
							<Col xs="12">{this.buildStripPlots()}</Col>
						</Row>
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = (
	{
		locations,
		groups,
		sites,
		lastUploadDate,
		steelSmeltersState,
		steelSmelterAllTimeBySmelter,
		steelSelectedSmelters,
		smelterShrinkedAllTimeData
	},
	{
		match: {
			params: { regionKey }
		}
	}
) => {
	const { regions, countries } = locations;

	const sortedRegions = regions
		.map((r) => r)
		.sort((r1, r2) => (r1.name > r2.name ? 1 : -1));

	if (sortedRegions.length) {
		sortedRegions.push({ id: '', name: 'All' });
	}

	for (const r of sortedRegions) {
		r.key = r.name.replace(/ /g, '').toLowerCase();
	}
	const sitesTree = arrayToTree(
		sites.filter(({ prod_state }) => prod_state === 0 || prod_state === 1),
		{
			dataField: null,
			parentId: 'parent_id'
		}
	);
	const flatten = (array) =>
		(array || []).flatMap(({ children, ...o }) => [o, ...flatten(children)]);

	const flattenTree = flatten(sitesTree);
	const sitesFromTree = [];
	flattenTree.forEach((s, i) => {
		const parent = s.parent_id
			? sitesFromTree.find(({ id }) => s.parent_id === id)
			: null;
		let newPlantType = '';
		if (!parent) {
			newPlantType = s.plant_type ? s.plant_type : 'Not Assigned';
		} else {
			newPlantType = s.plant_type
				? parent.newPlantType !== 'Not Assigned'
					? parent.newPlantType
					: s.plant_type
				: 'Not Assigned';
		}
		sitesFromTree.push({ ...s, newPlantType });
	});

	return {
		regions: sortedRegions,
		countries,
		groups,
		sites: sitesFromTree,
		// smelterRegions,
		lastUploadDate, // redux
		steelSmeltersState, // redux
		steelSmelterAllTimeBySmelter, //redux
		regionKey, // route url param
		steelSelectedSmelters, //redux
		smelterShrinkedAllTimeData //redux
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchLocations: (filterData) => dispatch(fetchLocations(filterData)),
	resetLocations: () => dispatch(resetLocations()),
	fetchGroups: (filterData) => dispatch(fetchGroups(filterData)),
	resetGroups: () => dispatch(resetGroups()),
	// fetchSites: (filterData) => dispatch(fetchSites(filterData)),
	// resetSites: () => dispatch(resetSites()),
	fetchLastUploadDate: (filterData) =>
		dispatch(fetchLastUploadDate(filterData)),
	resetLastUploadDate: () => dispatch(resetLastUploadDate()),
	fetchSteelSmeltersState: (filterData) =>
		dispatch(fetchSteelSmeltersState(filterData)),
	resetSteelSmeltersState: () => dispatch(resetSteelSmeltersState()),
	fetchSteelSmelterHotspotsAllTime: (filterData) =>
		dispatch(fetchSteelSmelterHotspotsAllTime(filterData)),
	resetSteelSmelterHotspotsAllTime: () =>
		dispatch(resetSteelSmelterHotspotsAllTime()),
	shrinkSmelterAllTimeMapData: ({ smelterData }) =>
		dispatch(shrinkSmelterAllTimeMapData({ smelterData })),
	resetShrinkedSmelterAllTimeMapData: () =>
		dispatch(resetShrinkedSmelterAllTimeMapData()),
	selectSteelSmelter: (id) => dispatch(selectSteelSmelter(id)),
	unselectSteelSmelter: (id) => dispatch(unselectSteelSmelter(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SteelSmeltersMap);
