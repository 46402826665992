//cognito user groups
const allTypes = ['cu', 'ni', 'steel'];

const cognitoGroups = {
	free: {
		cu: {
			required: 'savant-free',
			excludes: [
				'savant-premium',
				'savant-premium-plus',
				'sales',
				'savant-enterprise'
			]
		},
		ni: {
			required: 'savant-ni-free',
			excludes: [
				'savant-ni-premium',
				'savant-ni-premium-plus',
				'sales-ni',
				'savant-ni-enterprise'
			]
		},
		steel: {
			required: 'savant-steel-free',
			excludes: [
				'savant-steel-premium',
				'savant-steel-premium-plus',
				'sales-steel',
				'savant-steel-enterprise'
			]
		}
	},

	premium: {
		cu: {
			required: 'savant-premium',
			excludes: ['savant-premium-plus', 'sales', 'savant-enterprise']
		},
		ni: {
			required: 'savant-ni-premium',
			excludes: ['savant-ni-premium-plus', 'sales-ni', 'savant-ni-enterprise']
		},
		steel: {
			required: 'savant-steel-premium',
			excludes: [
				'savant-steel-premium-plus',
				'sales-steel',
				'savant-steel-enterprise'
			]
		}
	},

	premiumPlus: {
		cu: {
			required: 'savant-premium-plus',
			excludes: ['sales', 'savant-enterprise']
		},
		ni: {
			required: 'savant-ni-premium-plus',
			excludes: ['sales-ni', 'savant-ni-enterprise']
		},
		steel: {
			required: 'savant-steel-premium-plus',
			excludes: ['sales-steel', 'savant-steel-enterprise']
		}
	},

	sales: {
		cu: {
			required: 'sales',
			excludes: ['savant-enterprise']
		},
		ni: {
			required: 'sales-ni',
			excludes: ['savant-ni-enterprise']
		},
		steel: {
			required: 'sales-steel',
			excludes: ['savant-steel-enterprise']
		}
	},

	enterprise: {
		cu: {
			required: 'savant-enterprise',
			excludes: []
		},
		ni: {
			required: 'savant-ni-enterprise',
			excludes: []
		},
		steel: {
			required: 'savant-steel-enterprise',
			excludes: []
		}
	}
};

const envConfig = {
	apiGateway: {
		URL: process.env.REACT_APP_URL,
		REGION: process.env.REACT_APP_REGION
	},
	cognito: {
		REGION: process.env.REACT_APP_REGION,
		USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
		APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
		IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
		AUTH_FLOW_TYPE: process.env.REACT_APP_AUTH_FLOW_TYPE
	},
	ion: {
		accessToken: process.env.REACT_APP_ION_ACCESS_TOKEN
	},
	google: {
		ANALYTICS_ID: 'G-MVMRT4KQ4S',
		GA_ENABLED: process.env.REACT_APP_GA_ENABLED,
		TEST_MODE: true
	},

	idleTimeout: 1000 * 60 * 30
};

const apiEndpoints = {
	kmlDownload: '/kml-download/${id}', // eslint-disable-line no-template-curly-in-string
	mySmelters: '/my-smelters',
	lastUploadDate: '/last-upload-date?commodity=${commodity}', // eslint-disable-line no-template-curly-in-string
	groups: '/groups?commodity=${commodity}&siteType=${siteType}', // eslint-disable-line no-template-curly-in-string
	locations: '/locations?commodity=${commodity}&siteType=${siteType}', // eslint-disable-line no-template-curly-in-string
	listSites: '/sites?commodity=${commodity}', // eslint-disable-line no-template-curly-in-string
	listSiteTypes: '/site-types', // eslint-disable-line no-template-curly-in-string
	geoJsonData: '/sites/${id}/info', // eslint-disable-line no-template-curly-in-string

	//stockpiles
	stockpilesData:
		'/stockpiles-total-pixels/${grouping}/${ids}?commodity=${commodity}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	//scrapSteel
	scrapSteelData:
		'/scrap-steel-total-pixels/${grouping}/${ids}?startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	// stockpilesData:
	// 	'/stockpiles-data/sites/${ids}?commodity=${commodity}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	// 'steel'
	steelSmelterData:
		'/smelter-indices/${grouping}/${ids}?commodity=steel&plant=${plant}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterCapacity:
		'/smelter-capacity/${grouping}/${ids}?commodity=steel&plant=${plant}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterProduction:
		'/smelter-production/${grouping}/${ids}?commodity=steel&collapse=${collapse}&plant=${plant}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterCO2Emissions:
		'/smelter-co2-emissions/${grouping}/${ids}?commodity=steel&collapse=${collapse}&plant=${plant}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	worldSteelRegions: '/world-steel-regions/subtypes/${siteTypeId}', // eslint-disable-line no-template-curly-in-string
	worldSteelData:
		'/world-steel-data/${grouping}/${id}?collapse=${collapse}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	worldSteelSubsitesData:
		'/world-steel-data/${grouping}/${id}/subtypes/${siteTypeId}?collapse=${collapse}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterProductionSubsites:
		'/smelter-production-subsites/${grouping}/${ids}?sType=${sType}&collapse=${collapse}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelPseudoGroups: '/pseudo-groups?type=steel',
	steelSmelterAllMapData: '/smelter-map-data-all?type=steel',
	steelSmelterAllTimeBySmelter:
		'/smelter-data/sites/${smelterIds}?commodity=steel', // eslint-disable-line no-template-curly-in-string

	steelSmelterDataFree: '/smelter-data-free?type=steel',
	steelSmelterList: '/smelter-list?type=steel',
	steelSmeltersStatusAll:
		'/smelter-status/regions?commodity=steel&plantType=${plantType}&searchDate=${searchDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterStatusRegions:
		'/smelter-status/regions/${regionIds}?commodity=steel&plantType=${plantType}&searchDate=${searchDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterStatusCountries:
		'/smelter-status/countries/${countryIds}?commodity=steel&plantType=${plantType}&searchDate=${searchDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterRecent: '/smelter-state-recent',
	steelSmelterRecentUpdates: '/smelter-map-recent-updates?type=steel',
	steelSmelterRegions: '/smelter-map-regions?type=steel',
	steelSmelterUpdates:
		'/smelter-data-updates?type=steel&regions=${regions}&startDate=${startDate}&endDate=${endDate}' // eslint-disable-line no-template-curly-in-string
};

export default {
	apiEndpoints,
	allTypes,
	cognitoGroups,
	...envConfig
};
